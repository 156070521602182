import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class ObergruppeRepository extends AbstractRepository<Category> {

    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/obergruppe`;
    }

    getAll(): Promise<Category[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: Category[]) => resolve(res));
        });
    }

    update(obergruppe: Category) {
        return this.http.post(`${this.getUrl()}/update`, obergruppe);
    }

    post(obergruppe: Category) {
        return this.http.post(this.getUrl(), obergruppe);
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    getRepoName(): string {
        return 'Obergruppe-';
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/obergruppeImport`;
    }

    getCsvHeaders(): string[] {
        return [ // Spalten für die generierte .csv Datei
            'Name',
            'Beschreibung',
            'Color',
            'Icon'
        ];
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */
    import(importObjects: Category[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    buildObject(
        Name: string,
        Beschreibung: string,
        Color: string,
        Icon: string,
        ID?: number,
    ): Category {
        return {
            Description: Beschreibung,
            Name: Name,
            Color: Color,
            Icon: Icon,
            ID: ID,
            tenant: this.tenantService.getCurrentTenantID()
        };
    }

    buildImportObjects(importObjectStringArray): Category[] {

        const objectsFromCsv: Category[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const Name = importObjectStringArray[i][0];
            const Beschreibung = importObjectStringArray[i][1];
            const Color = importObjectStringArray[i][2];
            const Icon = importObjectStringArray[i][3];

            objectsFromCsv.push(this.buildObject(
                Name,
                Beschreibung,
                Color,
                Icon
            ));
        }
        return objectsFromCsv;
    }

    getWithID(id: number): Promise<Category> {
        throw new Error('Method not implemented.');
    }

}

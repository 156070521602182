import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DashboardDataProviderService } from '../../app/Services/dashboard-data-provider.service';
import { FormControl } from '@angular/forms';
import { DashboardMode } from '../../app/models/enums/dashboard-mode';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnChanges {

  @Input() bookings: Booking[];

  orders: any[];
  multi: any[];

  view: any[] = [580, 300];

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Artikel';
  showYAxisLabel = true;
  yAxisLabel = '€';

  colorScheme = {
    domain: ['#03cbcc']
  };
  @Input() dashboardMode: DashboardMode = DashboardMode.count;
  @Input() top: number = 3;

  loaded = false;
  selectType = new FormControl(this.dashboardMode);



  constructor(private dashboardDataProvider: DashboardDataProviderService) {
    Object.assign(this, { single: this.orders });
  }

  ngOnChanges(): void {
    console.log("efklöölef")
    this.calculateTop3Articles();
  }


  ngOnInit(): void {
    this.calculateTop3Articles();
  }

  calculateTop3Articles() {
    const bookList = [...this.bookings];
    this.dashboardDataProvider.getTop3Articles(this.dashboardMode, bookList, this.top).then((data: any[]) => {
      this.orders = data;
      this.loaded = true;
    });
  }

  typeChanged(event) {
    this.dashboardMode = (event === DashboardMode.count) ? DashboardMode.count : DashboardMode.profit;
    this.loaded = false;
    this.calculateTop3Articles();
  }
  onSelect(event) {
    console.log(event);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditArtikelComponent } from './edit/edit-artikel.component';
import { ArtikelRepository } from '../../models/artikel/artikel.repository';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { AddArtikelComponent } from './add-artikel/add-artikel.component';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LoadFileService } from 'src/app/Services/load-file.service';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  all: Article[];
  showed: Article[];

  tableOpen = false;
  editOpen = true;
  repo = this.artikelRepo;

  constructor(
    private artikelRepo: ArtikelRepository,
    private warenGruppeRepo: WarengruppeRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService

  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.getArtikel();
  }

  addArtikel() {
    this.dialogProvider.openAddOrEditDialog(AddArtikelComponent, this.all, () => { this.loadData(); });
  }

  deleteArtikel(artikel: Article) {
    this.dialogProvider.openDeleteDialog(artikel.Name, artikel.ID, this.artikelRepo, () => { this.loadData(); });
  }

  editArtikel(article: Article) {
    const data = { articleToEdit: article, allArtikel: this.all };
    this.dialogProvider.openAddOrEditDialog(EditArtikelComponent, data, () => { this.loadData(); });
  }

  openFileLoad() {
    const data = {
      exportData: this.showed,
      repo: this.artikelRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }

  toogleEditWindow() {
    this.tableOpen = !this.tableOpen;
    this.editOpen = !this.editOpen;
  }

  getArtikel() {
    this.artikelRepo.getAll().then((artikel: Article[]) => {
      this.all = artikel;
      this.showed = artikel;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { BerechtigungRepository } from 'src/app/models/berechtigung/berechtigung.repository';
import { MatDialog } from '@angular/material';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { AddBerechtigungComponent } from './add-berechtigung/add-berechtigung.component';
import { EditBerechtigungComponent } from './edit-berechtigung/edit-berechtigung.component';
import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-berechtigung',
  templateUrl: './berechtigung.component.html',
  styleUrls: ['./berechtigung.component.scss']
})
export class BerechtigungComponent implements OnInit {
  all: Permission[];
  showed: Permission[];
  constructor(
    private berechtigungRepo: BerechtigungRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.berechtigungRepo.getAll().then((berechtigung: Permission[]) => {
      this.all = berechtigung;
      this.showed = berechtigung;
    });
  }

  delete(berechtigung: Permission) {
    this.dialogProvider.openDeleteDialog(berechtigung.Name, berechtigung.ID, this.berechtigungRepo, () => { this.loadData(); });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddBerechtigungComponent, undefined, () => { this.loadData(); });
  }

  edit(berechtigung: Permission) {
    this.dialogProvider.openAddOrEditDialog(EditBerechtigungComponent, berechtigung, () => { this.loadData(); });
  }

  openFileLoad() {
    const data = {
      exportData: this.showed,
      repo: this.berechtigungRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }
}

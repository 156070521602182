import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DashboardDataProviderService } from '../../app/Services/dashboard-data-provider.service';
import { FormControl } from '@angular/forms';
import { DashboardMode, DayFilter } from '../../app/models/enums/dashboard-mode'
import { ArtikelRepository } from '../../app/models/artikel/artikel.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-profit-chart',
  templateUrl: './profitChart.component.html',
  styleUrls: ['./profitChart.component.scss'],
})


export class ProfitChartComponent implements OnInit, OnChanges {

  @ViewChild('artikels') artikelInput: ElementRef<HTMLInputElement>;
  @Input() bookings: Booking[];

  @Input() start: Date = null;
  @Input() end: Date = null;
  @Input() selectType: DashboardMode = DashboardMode.count;
  @Input() dayFilter: any[] = [];

  results = [];
  countType = DashboardMode.profit;
  view: any[] = [580, 380];
  legend = false;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Month';
  yAxisLabel = '';
  timeline = true;
  loaded = false;
  colorScheme = {
    domain: ['#03cbcc', 'green']
  };

  DashboardModeProfit = DashboardMode.profit;
  DashboardModeCount = DashboardMode.count;

  // start = new FormControl();
  // end = new FormControl();
  // selectType = new FormControl(this.countType);
  // dayFilter = new FormControl([]);

  showDayFilter = true;


  // tslint:disable-next-line: max-line-length
  constructor(private dashboardDataProvider: DashboardDataProviderService, public i18n: LocalizationService) {
  }

  calculateProfitData() {


    const bookList = [...this.bookings];
    this.dashboardDataProvider.getProfitData(this.selectType, bookList, this.start, this.end, this.dayFilter).then((productsByDate) => {
      this.results = productsByDate.data;
      this.loaded = true;
      this.xAxisLabel = productsByDate.type;
      if (productsByDate.type === DayFilter.daily || productsByDate.type === DayFilter.month) {
        this.showDayFilter = false;
      } else {
        this.showDayFilter = true;
      }

      console.log('====================================');
      console.log(this.dayFilter, this.start, this.end, this.selectType, this.results);
      return productsByDate;
    });
  }

  ngOnChanges(): void {
    this.calculateProfitData();
  }
  ngOnInit(): void {
    this.calculateProfitData();
  }

  typeChanged(type: DashboardMode) {
    this.countType = type;
    this.loaded = false;
    this.calculateProfitData();
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  checkDatesToFilter(dayFilter = [], fromDayFilter: boolean = false) {

    if ((this.start == null || this.end == null) && !fromDayFilter) {
      return;
    }
    const start = new Date(this.start);
    const end = new Date(this.end);

    if (
      start instanceof Date &&
      !isNaN(start.getTime()) &&
      end instanceof Date &&
      !isNaN(end.getTime()) &&
      this.start != null &&
      this.end != null) {

      this.loaded = false;
      this.calculateProfitData();
    } else {
      this.calculateProfitData();
    }

  }

  dayFilterChanged(event) {
    this.checkDatesToFilter(event, true);
  }


}

import { Component, OnInit } from '@angular/core';
import { ObergruppeRepository } from 'src/app/models/obergruppe/obergruppe.repository';
import { EditObergruppeComponent } from './edit-obergruppe/edit-obergruppe.component';
import { AddObergruppeComponent } from './add-obergruppe/add-obergruppe.component';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';

import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-obergruppe',
  templateUrl: './obergruppe.component.html',
  styleUrls: ['./obergruppe.component.scss']
})
export class ObergruppeComponent implements OnInit {
  all: Category[];
  showed: Category[];

  constructor(
    private obergruppeRepo: ObergruppeRepository,
    private warengruppeRepo: WarengruppeRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService
  ) { }


  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.obergruppeRepo.getAll().then((obergruppen: Category[]) => {
      this.all = obergruppen;
      this.showed = obergruppen;
    });
  }

  edit(obergruppe: Category) {
    this.dialogProvider.openAddOrEditDialog(EditObergruppeComponent, obergruppe, () => { this.loadData(); });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddObergruppeComponent, undefined, () => { this.loadData(); });
  }

  delete(obergruppe: Category) {
    let dependentSc: SubCategory[];
    const dependentElements: { type: string, element: string }[] = [];

    this.warengruppeRepo.getAll().then((subCategory: SubCategory[]) => {
      dependentSc = subCategory.filter(filterObjekt => filterObjekt.CategoryID === obergruppe.ID);
      if (dependentSc.length === 0) {
        this.dialogProvider.openDeleteDialog(obergruppe.Name, obergruppe.ID, this.obergruppeRepo, () => { this.loadData(); });
      } else {

        dependentSc.forEach(depSc => {
          dependentElements.push(
            { type: this.i18n.translate('subCategory'), element: depSc.Name }
          );
        });

        const data = {
          status: 'Achtung',
          message: 'Löschen nicht möglich. Folgende Elemente sind von diesem Objekt abhängig:',
          elements: dependentElements
        };
        this.dialogProvider.openConfirmationDialog(data);
      }
    });
  }

  openFileLoad() {
    const data = {
      exportData: this.showed,
      repo: this.obergruppeRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }
}

import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantRepository } from 'src/app/models/tenant/tenant.repository';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/assets/confirmation-dialog/confirmation-dialog.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit {

  tenantFromDB: Tenant;

  tenantFormGroup: FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      plz: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      steuernummer: new FormControl('', Validators.required),
      telefonnummer: new FormControl('', Validators.required),
    });

  constructor(
    private tenantRepo: TenantRepository,
    private tenantService: TenantSelectionService,
    private dialog: MatDialog,
    private dialogProvider: DialogProviderService, 
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadTenant();
  }

  safe() {
    this.tenantRepo.update(this.tenantRepo.buildObject(
      this.tenantFormGroup.get('name').value,
      this.tenantFormGroup.get('street').value,
      this.tenantFormGroup.get('plz').value,
      this.tenantFormGroup.get('city').value,
      this.tenantFormGroup.get('steuernummer').value,
      this.tenantFormGroup.get('telefonnummer').value,
    )).subscribe(res => {
      console.log(res);
      this.loadTenant();
      const data = { status: 'Erfolgreich', message: 'Ihre Änderungen wurden gespeichert.' };
      this.dialogProvider.openConfirmationDialog(data);
    });
  }


  loadTenant() {
    this.tenantRepo.getWithID(this.tenantService.getCurrentTenantID()).then(
      (tenant: Tenant) => {
        this.tenantFromDB = tenant;

        this.tenantFormGroup.get('name').setValue(this.tenantFromDB.name);
        this.tenantFormGroup.get('street').setValue(this.tenantFromDB.street);
        this.tenantFormGroup.get('plz').setValue(this.tenantFromDB.plz);
        this.tenantFormGroup.get('city').setValue(this.tenantFromDB.city);
        this.tenantFormGroup.get('country').setValue(this.tenantFromDB.country);
        this.tenantFormGroup.get('steuernummer').setValue(this.tenantFromDB.taxNumber);
        this.tenantFormGroup.get('telefonnummer').setValue(this.tenantFromDB.telefonNumber);
      }
    );
  }
}

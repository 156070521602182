import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class TenantRepository extends AbstractRepository<Tenant> {


    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/tenant`;
    }

    post(tenant: Tenant): Observable<any> {
        return this.http.post(this.getUrl(), tenant);
    }

    // Das braucht man bei Tenant doch gar nicht oder?
    getAll(): Promise<Tenant[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: Tenant[]) => resolve(res));
        })
    }

    getWithID(id: number): Promise<Tenant> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}/${this.tenantService.getCurrentTenantID()}`).subscribe((res: Tenant[]) => resolve(res[0]));
        });
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                ID: id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    update(tenant: Tenant) {
        return this.http.post(`${this.getUrl()}/update`, tenant);
    }

    buildObject(
        name: string,
        street: string,
        plz: number,
        city: string,
        steuernummer: string,
        telefonnummer: string,
        country?: string,
        username?: string
    ): Tenant {
        return {
            name,
            street,
            plz,
            city,
            country,
            taxNumber: steuernummer,
            telefonNumber: telefonnummer,
            username,
            ID: this.tenantService.getCurrentTenantID(),
        };
    }
    getUrlForImport(): string {
        throw new Error('Method not implemented.');
    }
    getCsvHeaders(): string[] {
        throw new Error('Method not implemented.');
    }
    import(importObjects: Tenant[]) {
        throw new Error('Method not implemented.');
    }
    getRepoName(): string {
        throw new Error('Method not implemented.');
    }
    buildImportObjects(importObjectStringArray: any): Tenant[] {
        throw new Error('Method not implemented.');
    }
}

import { Component, OnInit } from '@angular/core';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { EditWarengruppeComponent } from './edit/edit-warengruppe.component';
import { MatDialog } from '@angular/material';
import { AddWarengruppeComponent } from './add-warengruppe/add-warengruppe.component';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { ArtikelRepository } from 'src/app/models/artikel/artikel.repository';
import { ConfirmationDialogComponent } from 'src/assets/confirmation-dialog/confirmation-dialog.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';

import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-warengruppe',
  templateUrl: './warengruppe.component.html',
  styleUrls: ['./warengruppe.component.scss']
})
export class WarengruppeComponent implements OnInit {
  all: SubCategory[];
  showed: SubCategory[];

  constructor(
    private warengruppeRepo: WarengruppeRepository,
    private dialog: MatDialog,
    private artikelRepo: ArtikelRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService
  ) { }


  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.warengruppeRepo.getAll().then((warengruppen: SubCategory[]) => {
      this.all = warengruppen;
      this.showed = warengruppen;
    });
  }

  delete(subcategory: SubCategory) {
    let dependantArt: Article[];
    const dependentElements: { type: string, element: string }[] = [];

    this.artikelRepo.getAll().then((article: Article[]) => {
      dependantArt = article.filter(filterObjekt => filterObjekt.SubCategoryID === subcategory.ID);
      if (dependantArt.length === 0) {
        this.dialogProvider.openDeleteDialog(subcategory.Name, subcategory.ID, this.warengruppeRepo, () => { this.loadData(); });
      } else {

        dependantArt.forEach((depArt: Article) => {
          dependentElements.push(
            { type: this.i18n.translate('article'), element: depArt.Name }
          );
        });

        const data = {
          status: 'Achtung',
          message: 'Löschen nicht möglich. Folgende Elemente sind von diesem Objekt abhängig:',
          elements: dependentElements
        };
        this.dialogProvider.openConfirmationDialog(data);
      }
    });
  }


  add() {
    this.dialogProvider.openAddOrEditDialog(AddWarengruppeComponent, undefined, () => { this.loadData(); });
  }

  edit(warengruppe: SubCategory) {
    this.dialogProvider.openAddOrEditDialog(EditWarengruppeComponent, warengruppe, () => { this.loadData(); });
  }

  openFileLoad() {
    const data = {
      exportData: this.showed,
      repo: this.warengruppeRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BookingAction } from 'src/app/models/enums/booking-action';
import { LoadFileService } from 'src/app/Services/load-file.service';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements OnChanges, OnInit {

  @Output() openEditDialog = new EventEmitter<any>();
  @Output() openAddDialog = new EventEmitter();
  @Output() openDeleteDialog = new EventEmitter<any>();
  @Output() openFileLoadDialog = new EventEmitter();

  @Input() fileLoad = false;
  @Input() columns: { name: string, dataName: string }[];
  @Input() rows;
  @Input() disableEdit = false;
  @Input() disableDelete = false;
  @Input() disableAdd = false;
  @ViewChild('cellContent') cellContent: ElementRef;
  showedRows = [];
  tableOpen = true;
  editOpen = false;


  filterInput: { dataName: string, filterValue: string }[] = [];
  filterFormGroup: FormGroup = new FormGroup({});

  constructor(private loadFileService: LoadFileService, public i18n: LocalizationService) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.showedRows = this.rows;
  }

  ngOnInit() {
    this.createFilterFormGroup();
  }

  createFilterFormGroup() {
    this.columns.forEach(column => {
      this.filterFormGroup.addControl(column.name, new FormControl(''));
    });
  }

  filterRows(column) {

    const filterValue = this.filterFormGroup.get(column.name).value;
    const existentValue = this.filterInput.filter(filterObject => filterObject.dataName === column.dataName);

    if (existentValue.length === 0) {
      this.filterInput.push({
        dataName: column.dataName.toString(),
        filterValue: filterValue.toString()
      });
    } else if (existentValue.length !== 0 && filterValue !== '') {
      existentValue[0].filterValue = filterValue.toString();
    } else {
      this.filterInput = this.filterInput.filter(filterObject => filterObject.dataName !== existentValue[0].dataName);
    }

    let rowsCopy = this.rows;

    this.filterInput.forEach(filterElement => {
      rowsCopy = rowsCopy.filter(row => {
        return row[filterElement.dataName].toString().toLowerCase().includes(filterElement.filterValue.toLowerCase());
      });
    });

    this.showedRows = rowsCopy;
  }

  add(event) {
    this.openAddDialog.emit(event);
  }

  getBackgroundColor(row, column) {
    if (column.dataName === 'Action' && (row[column.dataName] === BookingAction.delete || row[column.dataName] === BookingAction.order)) {
      return row[column.dataName] === BookingAction.delete ? '#FB5151' : 'green';
    }
    if (column.dataName === 'Farbe') {
      return row[column.dataName];
    }
  }

  editRow(selectedRow: any) {
    this.openEditDialog.emit(selectedRow);
  }

  formatDate(timeStamp: any) {
    if (timeStamp !== 'empty') {
      return new Date(timeStamp).toLocaleString('de');
    } else {
      return this.i18n.translate('stillActive');
    }
  }

  deleteRow(selectedRow: any) {
    this.openDeleteDialog.emit(selectedRow);
  }

  toogleEditWindow() {
    this.tableOpen = !this.tableOpen;
    this.editOpen = !this.editOpen;
  }

  openFileload(event) {
    this.openFileLoadDialog.emit(event);
  }

  getIconPath(_icon) {
    const icon = JSON.parse(`{${_icon}}`);
    return `assets/images/${icon.name}.png`;
  }

}

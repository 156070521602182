export const REACT_NATIVE_COMPATIBLE_ICONS: { rnName: string, rnType: string }[] = [

    // FontAwesome5
    {
        rnName: 'hamburger', rnType: 'FontAwesome5'
    },
    {
        rnName: 'ice-cream', rnType: 'FontAwesome5'
    },
    {
        rnName: 'beer', rnType: 'FontAwesome5'
    },
    {
        rnName: 'cocktail', rnType: 'FontAwesome5'
    },
    {
        rnName: 'wine-glass-alt', rnType: 'FontAwesome5'
    },
    {
        rnName: 'pizza-slice', rnType: 'FontAwesome5'
    },
    {
        rnName: 'mug-hot', rnType: 'FontAwesome5'
    },
    {
        rnName: 'hotdog', rnType: 'FontAwesome5'
    },
    // Entypo
    {
        rnName: 'drink', rnType: 'Entypo'
    },
    // MaterialCommunityIcons
    {
        rnName: 'baguette', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'fruit-watermelon', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'water', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'fruit-cherries', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'fruit-citrus', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'fruit-grapes', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'fruit-pineapple', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'food-apple', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'food', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'food-fork-drink', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'food-croissant', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'food-steak', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'food-drumstick', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'tea', rnType: 'MaterialCommunityIcons'
    },
    {
        rnName: 'tea-outline', rnType: 'MaterialCommunityIcons'
    },

    // MaterialIcons

    {
        rnName: 'restaurant', rnType: 'MaterialIcons'
    },
]
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class PrinterRepository extends AbstractRepository<Printer> {


    constructor(
        private http: HttpClient,
        private tenantService: TenantSelectionService,
        private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/printer`;
    }

    post(printer: Printer) {
        return this.http.post(this.getUrl(), printer);
    }

    getAll(): Promise<Printer[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: Printer[]) => resolve(res));
        });
    }


    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    import(importObjects: Printer[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    update(printer: Printer) {
        return this.http.post(`${this.getUrl()}/update`, printer);
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/printerImport`;
    }

    getCsvHeaders(): string[] {
        return [ // Spalten für die generierte .csv Datei
            'IPAdress',
            'Description'
        ];
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */


    getRepoName(): string {
        return 'Printer-';
    }

    buildObject(
        IPAdress: string,
        Description: string,
        Port: string,
        ID?: number,
    ): Printer {
        return {
            IPAdress,
            Description,
            Port,
            ID,
            tenant: this.tenantService.getCurrentTenantID(),
        };
    }

    buildImportObjects(importObjectStringArray): Printer[] {

        const objectsFromCsv: Printer[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const IPAdress = importObjectStringArray[i][0];
            const Description = importObjectStringArray[i][1];
            const Port = importObjectStringArray[i][2];

            objectsFromCsv.push(this.buildObject(
                IPAdress,
                Description,
                Port
            ));
        }
        return objectsFromCsv;
    }

    getWithID(id: number): Promise<Printer> {
        throw new Error('Method not implemented.');
    }

}

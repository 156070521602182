import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { EditComponent } from '../edit/edit.component';
import { KontenRepository } from 'src/app/models/konten/konten.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  zahlungsmethoden = [{
    name: 'Bar',
  },
  {
    name: 'PAYDIREKT',
  },
  {
    name: 'PayPal'
  }
  ];

  typen = [{
    name: 'Einzahlung',
  },
  {
    name: 'Auszahlung',
  },
  ];

  kontenFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl('', Validators.required),
      Kontonummer: new FormControl('', Validators.required),
      Typ: new FormControl('', Validators.required),
      Zahlungsmethode: new FormControl('', Validators.required),
      Gutschein: new FormControl(''),
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    private kontenRepo: KontenRepository, 
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  add() {
    if (this.validate()) {
      this.kontenRepo.post(this.buildKonto()).subscribe(test => console.log(test));
      this.dialogRef.close('submit');
    }
  }

  buildKonto(): TransactionAccount {
    return this.kontenRepo.buildObject(
      this.kontenFormGroup.get('Name').value,
      this.kontenFormGroup.get('Kontonummer').value,
      this.kontenFormGroup.get('Typ').value,
      this.kontenFormGroup.get('Zahlungsmethode').value,
      this.kontenFormGroup.get('Gutschein').value
    );
  }

  private validate() {
    if (
      this.kontenFormGroup.get('Name').valid &&
      this.kontenFormGroup.get('Kontonummer').valid &&
      this.kontenFormGroup.get('Typ').valid &&
      this.kontenFormGroup.get('Zahlungsmethode').valid &&
      this.kontenFormGroup.get('Gutschein').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { REACT_NATIVE_COMPATIBLE_ICONS } from '../rn-icons/rn-icons';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss']
})

export class IconPickerComponent implements OnInit {

  icons = REACT_NATIVE_COMPATIBLE_ICONS;

  @Input() colors: { name: string }[];

  constructor(
    public dialogRef: MatDialogRef<IconPickerComponent>) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  getIconPath(icon) {
    return `assets/images/${icon.rnName}.png`;
  }

  getColor() {
    return '#009a9b';
  }

  chooseIcon(icon) {
    this.dialogRef.close(icon);
  }
}

import { Component, OnInit } from '@angular/core';
import { SteuersatzRepository } from 'src/app/models/steuersatz/steuersatz.repository';
import { EditSteuerComponent } from './edit-steuer/edit-steuer.component';
import { AddSteuerComponent } from './add-steuer/add-steuer.component';
import { ArtikelRepository } from 'src/app/models/artikel/artikel.repository';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LocalizationService } from 'src/app/Services/localization.service';
@Component({
  selector: 'app-steuer',
  templateUrl: './steuer.component.html',
  styleUrls: ['./steuer.component.scss']
})
export class SteuerComponent implements OnInit {
  all: Tax[];
  showed: Tax[];

  constructor(
    private steuersatzRepo: SteuersatzRepository,
    private artikelRepo: ArtikelRepository,
    private warengruppeRepo: WarengruppeRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService
  ) { }


  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.steuersatzRepo.getAll().then((steuersatz: Tax[]) => {
      this.all = steuersatz;
      this.showed = steuersatz;
    });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddSteuerComponent, undefined, () => { this.loadData(); });
  }

  delete(steuerSatz: Tax) {
    let dependentWg;
    let dependentArt;
    const dependentElements: { type: string, element: string }[] = [];

    this.warengruppeRepo.getAll().then((warengruppe: SubCategory[]) => {
      dependentWg = warengruppe.filter(filterWg =>
        filterWg.Tax1ID === steuerSatz.ID || filterWg.Tax2ID === steuerSatz.ID
      );
      this.artikelRepo.getAll().then((artikel: Article[]) => {
        dependentArt = artikel.filter(filterArt =>
          parseInt(filterArt.Tax1ID, 10) === steuerSatz.ID || parseInt(filterArt.Tax2ID, 10) === steuerSatz.ID);

        if (dependentWg.length === 0 && dependentArt.length === 0) {
          this.dialogProvider.openDeleteDialog(steuerSatz.Name, steuerSatz.ID, this.steuersatzRepo, () => { this.loadData(); });
        } else {

          dependentWg.forEach((depWg: SubCategory) => {
            dependentElements.push(
              { type: this.i18n.translate('subCategory'), element: depWg.Name }
            );
          });
          dependentArt.forEach(depArt => {
            dependentElements.push(
              { type: this.i18n.translate('article'), element: depArt.Name }
            );
          });

          const datas = {
            status: 'Achtung',
            message: 'Löschen nicht möglich. Folgende Elemente sind von diesem Objekt abhängig:',
            elements: dependentElements
          };
          this.dialogProvider.openConfirmationDialog(datas);
        }
      });
    });
  }

  edit(steuersatz: Tax) {
    this.dialogProvider.openAddOrEditDialog(EditSteuerComponent, steuersatz, () => { this.loadData(); });
  }

  openFileLoad() {
    const datas = {
      exportData: this.showed,
      repo: this.steuersatzRepo
    };
    this.dialogProvider.openFileLoadDialog(datas, () => { this.loadData(); });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BerechtigungRepository } from 'src/app/models/berechtigung/berechtigung.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-edit-berechtigung',
  templateUrl: './edit-berechtigung.component.html',
  styleUrls: ['./edit-berechtigung.component.scss']
})
export class EditBerechtigungComponent implements OnInit {
  nutzers: User[];
  berechtigungFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl(this.data.Name, Validators.required),
      Beschreibung: new FormControl(this.data.Description, Validators.required),
      Funktionen: new FormControl(this.data.Functions, Validators.required),
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Permission,
    public dialogRef: MatDialogRef<EditBerechtigungComponent>,
    private berechtigungRepo: BerechtigungRepository, 
    public i18n: LocalizationService
  ) { }

  ngOnInit() {

  }

  edit() {
    if (this.validate()) {
      this.berechtigungRepo.update(this.buildObject()).subscribe(
        res => {
          console.log(res);
          this.dialogRef.close('submit');
        }
      );
    }
  }

  buildObject(): Permission {
    return this.berechtigungRepo.buildObject(
      this.berechtigungFormGroup.get('Name').value,
      this.berechtigungFormGroup.get('Beschreibung').value,
      this.berechtigungFormGroup.get('Funktionen').value,
      this.data.ID
    );
  }

  private validate() {
    if (
      this.berechtigungFormGroup.get('Name').valid &&
      this.berechtigungFormGroup.get('Beschreibung').valid &&
      this.berechtigungFormGroup.get('Funktionen').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NutzerRepository } from 'src/app/models/nutzer/nutzer.repository';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-edit-nutzer',
  templateUrl: './edit-nutzer.component.html',
  styleUrls: ['./edit-nutzer.component.scss']
})
export class EditNutzerComponent implements OnInit {

  nutzerFormGroup: FormGroup = new FormGroup(
    {
      Login: new FormControl(this.data.Login, Validators.required),
      Name: new FormControl(this.data.Name, Validators.required),
      Berechtigungsgruppe: new FormControl(this.data.PermissionGroupID, Validators.required)
    }
  )

  constructor(
    public dialogRef: MatDialogRef<EditNutzerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private nutzerRepo: NutzerRepository,
    public i18n: LocalizationService

  ) { }

  ngOnInit() {
  }

  edit() {
    if (this.validate()) {
      this.nutzerRepo.update(this.buildNutzer()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  buildNutzer(): User {
    return this.nutzerRepo.buildObject(
      this.nutzerFormGroup.get('Login').value,
      this.nutzerFormGroup.get('Name').value,
      this.nutzerFormGroup.get('Berechtigungsgruppe').value,
      this.data.ID
    )
  }

  validate() {
    if (this.nutzerFormGroup.get('Login').valid &&
      this.nutzerFormGroup.get('Name').valid &&
      this.nutzerFormGroup.get('Berechtigungsgruppe').valid) {
      return true;
    }
    return false;
  }

}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {
  MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatCheckboxModule,
  MAT_DIALOG_DATA, MatButtonToggleModule, MatSlideToggleModule, MatAutocompleteModule, MatProgressSpinnerModule
} from '@angular/material';
import { ArticleComponent } from './components/article/article.component';
import { TableViewComponent } from '../assets/table-view/table-view.component';
import { EditArtikelComponent } from './components/article/edit/edit-artikel.component';
import { WarengruppeComponent } from './components/warengruppe/warengruppe.component';
import { EditWarengruppeComponent } from './components/warengruppe/edit/edit-warengruppe.component';
import { SteuerComponent } from './components/steuer/steuer.component';
import { NutzerComponent } from './components/nutzer/nutzer.component';
import { EditNutzerComponent } from './components/nutzer/edit-nutzer/edit-nutzer.component';
import { ObergruppeComponent } from './components/obergruppe/obergruppe.component';
import { EditObergruppeComponent } from './components/obergruppe/edit-obergruppe/edit-obergruppe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddArtikelComponent } from './components/article/add-artikel/add-artikel.component';
import { DeleteDialogComponent } from '../assets/delete-dialog/delete-dialog.component';
import { EditSteuerComponent } from './components/steuer/edit-steuer/edit-steuer.component';
import { AddSteuerComponent } from './components/steuer/add-steuer/add-steuer.component';
import { AddWarengruppeComponent } from './components/warengruppe/add-warengruppe/add-warengruppe.component';
import { AddObergruppeComponent } from './components/obergruppe/add-obergruppe/add-obergruppe.component';
import { AddNutzerComponent } from './components/nutzer/add-nutzer/add-nutzer.component';
import { ModifierComponent } from './components/modifier/modifier.component';
import { EditModifierComponent } from './components/modifier/edit-modifier/edit-modifier.component';
import { AddModifierComponent } from './components/modifier/add-modifier/add-modifier.component';
import { BerechtigungComponent } from './components/berechtigung/berechtigung.component';
import { EditBerechtigungComponent } from './components/berechtigung/edit-berechtigung/edit-berechtigung.component';
import { AddBerechtigungComponent } from './components/berechtigung/add-berechtigung/add-berechtigung.component';
import { BuchungComponent } from './components/buchung/buchung.component';
import { ChartComponent } from '../assets/chart/chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartCircleComponent } from '../assets/chart-circle/chart-circle.component';
import { RabattComponent } from './components/rabatt/rabatt.component';
import { LoginComponent } from './components/public/login/login.component';
import { ColorPickerComponent } from '../assets/color-picker/color-picker.component';
import { IconPickerComponent } from '../assets/icon-picker/icon-picker.component';
import { KontenComponent } from './components/konten/konten.component';
import { EditComponent } from './components/konten/edit/edit.component';
import { AddComponent } from './components/konten/add/add.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { ConfirmationDialogComponent } from '../assets/confirmation-dialog/confirmation-dialog.component'
import { FileLoadComponent } from '../assets/file-load/file-load.component';
import { EditDialogComponent } from '../assets/edit-dialog/edit-dialog.component';
import { TextInputComponent } from '../assets/text-input/text-input.component';
import { CancelReasonComponent } from './components/cancel-reason/cancel-reason.component';
import { EditCancelReasonComponent } from './components/cancel-reason/edit-cancel-reason/edit-cancel-reason.component';
import { AddCancelReasonComponent } from './components/cancel-reason/add-cancel-reason/add-cancel-reason.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RegisterComponent } from './components/accounts/register/register.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { DeviceComponent } from './components/device/device.component';
import { PrinterComponent } from './components/printer/printer.component';
import { EditPrinterComponent } from './components/printer/edit-printer/edit-printer.component';
import { AddPrinterComponent } from './components/printer/add-printer/add-printer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfitChartComponent } from 'src/assets/profit-chart/profitChart.component';
import { MatNativeDateModule } from '@angular/material/core';
import { DailyClosingComponent } from './components/daily-closing/daily-closing.component';
import { AddDeviceComponent } from './components/device/add-device/add-device.component';
import { AddonComponent } from './components/addon/addon.component';
import { AddAddonComponent } from './components/addon/add-addon/add-addon.component';
import { EditAddonComponent } from './components/addon/edit-addon/edit-addon.component';
@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    ArticleComponent,
    TableViewComponent,
    EditArtikelComponent,
    WarengruppeComponent,
    EditWarengruppeComponent,
    SteuerComponent,
    NutzerComponent,
    EditNutzerComponent,
    ObergruppeComponent,
    EditObergruppeComponent,
    AddArtikelComponent,
    DeleteDialogComponent,
    EditSteuerComponent,
    AddSteuerComponent,
    AddWarengruppeComponent,
    AddObergruppeComponent,
    AddNutzerComponent,
    ModifierComponent,
    EditModifierComponent,
    AddModifierComponent,
    BerechtigungComponent,
    EditBerechtigungComponent,
    AddBerechtigungComponent,
    BuchungComponent,
    DashboardComponent,
    ChartComponent,
    ChartCircleComponent,
    RabattComponent,
    LoginComponent,
    ColorPickerComponent,
    IconPickerComponent,
    KontenComponent,
    EditComponent,
    AddComponent,
    TenantComponent,
    ConfirmationDialogComponent,
    FileLoadComponent,
    EditDialogComponent,
    TextInputComponent,
    CancelReasonComponent,
    EditCancelReasonComponent,
    AddCancelReasonComponent,
    RegisterComponent,
    AccountsComponent,
    DeviceComponent,
    ProfitChartComponent,
    PrinterComponent,
    EditPrinterComponent,
    AddPrinterComponent,
    DailyClosingComponent,
    AddDeviceComponent,
    AddonComponent,
    AddAddonComponent,
    EditAddonComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatNativeDateModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DeleteDialogComponent,

    ConfirmationDialogComponent,

    FileLoadComponent,

    EditArtikelComponent,
    AddArtikelComponent,

    EditCancelReasonComponent,
    AddCancelReasonComponent,

    EditSteuerComponent,
    AddSteuerComponent,

    EditWarengruppeComponent,
    AddWarengruppeComponent,

    EditObergruppeComponent,
    AddObergruppeComponent,

    EditModifierComponent,
    AddModifierComponent,

    EditArtikelComponent,
    AddArtikelComponent,

    EditNutzerComponent,
    AddNutzerComponent,

    AddWarengruppeComponent,
    EditWarengruppeComponent,

    AddBerechtigungComponent,
    EditBerechtigungComponent,

    AddPrinterComponent,
    EditPrinterComponent,


    AddAddonComponent,
    EditAddonComponent,

    ColorPickerComponent,
    IconPickerComponent,

    AddComponent,
    EditComponent,

    EditDialogComponent,

    AddDeviceComponent,
    RegisterComponent,

  ],
})
export class AppModule { }

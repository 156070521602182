import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';


@Injectable({
    providedIn: 'root'
})
export class ModifierRepository {

    constructor(
        private http: HttpClient,
        private tenantService: TenantSelectionService,
        private authBuilder: BuildAuthService
    ) { }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/modifier`;
    }

    post(modifier: Modifier) {
        return this.http.post(this.getUrl(), modifier);
    }

    getAll(): Observable<any> {
        return this.http.get(`${this.getUrl()}`);
    }

    getWithID(id: string) {
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    update(modifier: Modifier) {
        return this.http.post(`${this.getUrl()}/update`, modifier);
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/modifierImport`;
    }

    getCsvHeaders(): string[] {
        return [ // Spalten für die generierte .csv Datei
            'Name',
            'Beschreibung',
            'Farbe',
            'Wert',
            'Einheit'
        ];
    }

    getRepoName(): string {
        return 'Modifier-';
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */
    import(importObjects: Tax) {

        return this.http.post(this.getUrlForImport(), importObjects);
    }

    buildObject(
        Name: string,
        Description: string,
        Color: string,
        Value: number,
        Unit: string,
        ID?: number,
    ): Modifier {
        return {
            Name,
            Description,
            Color,
            Value,
            Unit,
            ID,
            tenant: this.tenantService.getCurrentTenantID(),
        };
    }

    buildImportObjects(importObjectStringArray): Modifier[] {

        const objectsFromCsv: Modifier[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const Name = importObjectStringArray[i][0];
            const Beschreibung = importObjectStringArray[i][1];
            const Farbe = importObjectStringArray[i][2];
            const Wert = parseInt(importObjectStringArray[i][2], 10);
            const Einheit = importObjectStringArray[i][2];

            objectsFromCsv.push(this.buildObject(
                Name,
                Beschreibung,
                Farbe,
                Wert,
                Einheit
            ));
        }
        return objectsFromCsv;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class ArtikelRepository extends AbstractRepository<Article> {


    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/artikel`;
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/artikelImport`;
    }

    getCsvHeaders(): string[] {
        return [
            // Spalten für die generierte .csv Datei
            'PLU',
            'Name',
            'Shortname',
            'Price1',
            'Tax1ID',
            'Tax2ID',
            'Printer',
            'Freeprice',
            'Favorite',
            'Price2',
            'Price3',
            'Price4',
            'Price5',
            'FreepriceMin',
            'FreepriceMax',
            'SubCategoryID'
        ];
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */
    import(importObjects: Article[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    getRepoName(): string {
        return 'Artikel-';
    }

    post(artikel: Article) {
        return this.http.post(this.getUrl(), artikel);
    }

    getAll(): Promise<Article[]> {
        return new Promise((resolve, reject) => {

            this.http.get(`${this.getUrl()}`).subscribe((res: Article[]) => resolve(res));
        });
    }

    getWithID(id: number): Promise<Article> {
        throw new Error('Method not implemented.');
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                ID: id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    update(artikel: Article) {
        return this.http.post(`${this.getUrl()}/update`, artikel);
    }

    buildObjectWithPreis(
        PLU: string,
        Name: string,
        Shortname: string,
        SubCategoryID: number,
        Tax1ID: string,
        Tax2ID: string,
        Printer: string,
        Favorite: boolean,
        Price1: number,
        Price2: number,
        Price3: number,
        Price4: number,
        Price5: number,
        LinkArticle: string,
        Addable: boolean,
        ID?: number,
    ): Article {
        return {
            PLU,
            Shortname,
            Favorite,
            Price1,
            Price2,
            Price3,
            Price4,
            Price5,
            SubCategoryID,
            Tax1ID,
            Tax2ID,
            LinkArticle,
            Printer,
            Name,
            Freeprice: false,
            FreepriceMin: 0,
            FreepriceMax: 0,
            ID,
            Addable,
            tenant: this.tenantService.getCurrentTenantID(),
        };
    }

    buildObjectWithFreipreis(
        PLU: string,
        Name: string,
        Shortname: string,
        SubCategoryID: number,
        Tax1ID: string,
        Tax2ID: string,
        Printer: string,
        Favorite: boolean,
        FreepriceMin: number,
        FreepriceMax: number,
        LinkArticle: string,
        Addable: boolean,
        ID?: number,
    ): Article {
        return {
            PLU,
            Shortname,
            SubCategoryID,
            Tax1ID,
            Tax2ID,
            LinkArticle,
            Printer,
            Name,
            Favorite,
            Freeprice: true,
            FreepriceMin,
            FreepriceMax,
            Price1: 0,
            Price2: 0,
            Price3: 0,
            Price4: 0,
            Price5: 0,
            Addable,
            ID,
            tenant: this.tenantService.getCurrentTenantID(),
        };
    }

    /**
     * This method builds objects from the given parameter.
     * @param importObjectStringArray - The Array of objects that need to be build.
     */
    buildImportObjects(importObjectStringArray): Article[] {

        const objectsFromCsv: Article[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const PLU = importObjectStringArray[i][0];
            const Name = importObjectStringArray[i][1];
            const Kurzname = importObjectStringArray[i][2];
            const Preis1 = parseFloat(importObjectStringArray[i][3]);
            const Steuersatz1ID = importObjectStringArray[i][4];
            const Steuersatz2ID = importObjectStringArray[i][5];
            const Drucker = importObjectStringArray[i][6];
            const Freipreis = !!+importObjectStringArray[i][7];
            const Favorit = !!+importObjectStringArray[i][8];
            const Preis2 = parseFloat(importObjectStringArray[i][9]);
            const Preis3 = parseFloat(importObjectStringArray[i][10]);
            const Preis4 = parseFloat(importObjectStringArray[i][11]);
            const Preis5 = parseFloat(importObjectStringArray[i][12]);
            const FreipreisMin = parseFloat(importObjectStringArray[i][13]);
            const FreipreisMax = parseFloat(importObjectStringArray[i][14]);
            const WarengruppeID = parseInt(importObjectStringArray[i][15], 10);
            const addable = !!+importObjectStringArray[i][16];
            if (Freipreis) {
                objectsFromCsv.push(this.buildObjectWithFreipreis(
                    PLU,
                    Name,
                    Kurzname,
                    WarengruppeID,
                    Steuersatz1ID,
                    Steuersatz2ID,
                    Drucker,
                    Favorit,
                    FreipreisMin,
                    FreipreisMax,
                    'import', // Linkartikel,
                    addable

                ));
            } else if (!Freipreis) {
                objectsFromCsv.push(this.buildObjectWithPreis(
                    PLU,
                    Name,
                    Kurzname,
                    WarengruppeID,
                    Steuersatz1ID,
                    Steuersatz2ID,
                    Drucker,
                    Favorit,
                    Preis1,
                    Preis2,
                    Preis3,
                    Preis4,
                    Preis5,
                    'import', // Linkartikel,
                    addable
                ));
            }
        }
        return objectsFromCsv;
    }

    buildObject(): Article {
        throw new Error('Method not implemented.');
    }
}

import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PrinterRepository } from 'src/app/models/printer/printer.repository';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-printer',
  templateUrl: './edit-printer.component.html',
  styleUrls: ['./edit-printer.component.scss']
})
export class EditPrinterComponent implements OnInit {

  printerFormGroup: FormGroup = new FormGroup(
    {
      IPAdress: new FormControl(this.data.IPAdress, Validators.required),
      Description: new FormControl(this.data.Description, Validators.required),
      Port: new FormControl(this.data.Port, Validators.required),
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Printer,
    public dialogRef: MatDialogRef<EditPrinterComponent>,
    private printerRepo: PrinterRepository,
  ) { }

  ngOnInit() {
  }

  edit() {
    if (this.validate()) {
      this.printerRepo.update(this.buildPrinter()).subscribe(
        res => {
          console.log(res);
          this.dialogRef.close('submit');
        }
      );
    }
  }

  buildPrinter(): Printer {
    return this.printerRepo.buildObject(
      this.printerFormGroup.get('IPAdress').value,
      this.printerFormGroup.get('Description').value,
      this.printerFormGroup.get('Port').value,
      this.data.ID
    );
  }

  private validate() {
    if (
      this.printerFormGroup.get('IPAdress').valid &&
      this.printerFormGroup.get('Description').valid &&
      this.printerFormGroup.get('Port').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { SteuersatzRepository } from 'src/app/models/steuersatz/steuersatz.repository';
import { ArtikelRepository } from 'src/app/models/artikel/artikel.repository';
import { FormGroup, FormControl, Validators, FormsModule, ValidatorFn } from '@angular/forms';
import { PrinterRepository } from 'src/app/models/printer/printer.repository';
import { LocalizationService } from 'src/app/Services/localization.service';


@Component({
  selector: 'app-add-artikel',
  templateUrl: './add-artikel.component.html',
  styleUrls: ['./add-artikel.component.scss']
})
export class AddArtikelComponent implements OnInit {
  pluShouldBeUnique: string;
  warengruppen: SubCategory[];
  steuerSaetze: Tax[];
  allArtikel: Article[];
  printers: Printer[];

  warengruppeSteuer:
    { name: string, ID: number } = { name: 'Wie Warengruppe', ID: 0 };

  artikelFormGroup: FormGroup = new FormGroup(
    {
      PLU: new FormControl('', Validators.required),
      Kurzname: new FormControl('', Validators.required),
      Preis1: new FormControl(0, Validators.required),
      Preis2: new FormControl(0),
      Preis3: new FormControl(0),
      Preis4: new FormControl(0),
      Preis5: new FormControl(0),
      WarengruppeID: new FormControl(Validators.required),
      Steuersatz1ID: new FormControl('', Validators.required),
      Steuersatz2ID: new FormControl('', Validators.required),
      LinkArtikel: new FormControl(''),
      Printer: new FormControl(0),
      Name: new FormControl('', [Validators.required, Validators.maxLength(35)]),
      Freipreis: new FormControl(false, Validators.required),
      FreipreisMin: new FormControl('', Validators.required),
      FreipreisMax: new FormControl('', Validators.required),
      Favorit: new FormControl(''),
      Addable: new FormControl(false, Validators.required)
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Article[],
    public dialogRef: MatDialogRef<AddArtikelComponent>,
    private warenGruppeRepo: WarengruppeRepository,
    private steuerRepo: SteuersatzRepository,
    private artikelRepo: ArtikelRepository,
    private printerRepo: PrinterRepository,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadData();
    this.allArtikel = this.data;
  }

  addArtikel() {
    if (this.validate()) {
      this.artikelRepo.post(this.buildArtikel()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  buildArtikel(): Article {

    let linkArticleID;
    if (this.artikelFormGroup.get('LinkArtikel').value) {
      linkArticleID = this.artikelFormGroup.get('LinkArtikel').value.ID;
    } else {
      linkArticleID = '';
    }

    if (!this.artikelFormGroup.get('Freipreis').value) {
      return this.artikelRepo.buildObjectWithPreis(

        this.artikelFormGroup.get('PLU').value,
        this.artikelFormGroup.get('Name').value,
        this.artikelFormGroup.get('Kurzname').value,
        this.artikelFormGroup.get('WarengruppeID').value,
        this.artikelFormGroup.get('Steuersatz1ID').value,
        this.artikelFormGroup.get('Steuersatz2ID').value,
        this.artikelFormGroup.get('Printer').value,
        this.artikelFormGroup.get('Favorit').value,
        this.artikelFormGroup.get('Preis1').value,
        this.artikelFormGroup.get('Preis2').value,
        this.artikelFormGroup.get('Preis3').value,
        this.artikelFormGroup.get('Preis4').value,
        this.artikelFormGroup.get('Preis5').value,
        this.artikelFormGroup.get('LinkArtikel').value.ID,
        this.artikelFormGroup.get('Addable').value
      );

    } else {
      return this.artikelRepo.buildObjectWithFreipreis(

        this.artikelFormGroup.get('PLU').value,
        this.artikelFormGroup.get('Name').value,
        this.artikelFormGroup.get('Kurzname').value,
        this.artikelFormGroup.get('WarengruppeID').value,
        this.artikelFormGroup.get('Steuersatz1ID').value,
        this.artikelFormGroup.get('Steuersatz2ID').value,
        this.artikelFormGroup.get('Printer').value,
        this.artikelFormGroup.get('Favorit').value,
        this.artikelFormGroup.get('FreipreisMin').value,
        this.artikelFormGroup.get('FreipreisMax').value,
        this.artikelFormGroup.get('LinkArtikel').value.ID,
        this.artikelFormGroup.get('Addable').value
      );
    }
  }

  validate() {

    if (
      this.validatePlu() &&
      this.artikelFormGroup.get('Kurzname').valid &&
      this.artikelFormGroup.get('Preis1').valid &&
      this.artikelFormGroup.get('WarengruppeID').valid &&
      this.artikelFormGroup.get('Steuersatz1ID').valid &&
      this.artikelFormGroup.get('Steuersatz2ID').valid &&
      this.artikelFormGroup.get('LinkArtikel').valid &&
      this.artikelFormGroup.get('Printer').valid &&
      this.artikelFormGroup.get('Name').valid
    ) {
      return true;
    }
    return false;
  }

  validatePlu() {

    const plu = this.artikelFormGroup.get('PLU').value;
    let valid = true;

    this.allArtikel.forEach(article => {
      if (valid) {
        valid = plu === article.PLU.toString() ? false : true;
      }
    });
    this.pluShouldBeUnique = valid ? '' : 'Die Plu muss eindeutig sein';
    return valid && this.artikelFormGroup.get('PLU').valid ? true : false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  loadData() {
    this.getWarenGruppen();
    this.getSteuer();
    this.getPrinter();
  }

  getWarenGruppen() {
    this.warenGruppeRepo.getAll().then((warenGruppen: SubCategory[]) => {
      this.warengruppen = warenGruppen;
    }
    );
  }

  getSteuer() {
    this.steuerRepo.getAll().then((steuerSaetze: Tax[]) => {
      this.steuerSaetze = steuerSaetze;
    }
    );
  }

  getPrinter() {
    this.printerRepo.getAll().then((printer: Printer[]) => {
      this.printers = printer;
    }
    );
  }

  nameOfArtikel = (artikel: Article): string => {
    if (artikel) {
      return artikel.Name;
    } else {
      return '';
    }
  }

  filter() {
    const value = this.artikelFormGroup.get('LinkArtikel').value;
    this.allArtikel = this.data.filter(artikel => artikel.Name.toLowerCase().includes(value.toString().toLowerCase()));
  }
}

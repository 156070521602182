import { Observable } from 'rxjs';

export abstract class AbstractRepository<T> {

    abstract getUrl(): string;
    abstract getAll(): Promise<T[]>;
    abstract getWithID(id: number): Promise<T>;
    abstract update(object: T): Observable<any>;
    abstract post(object: T): Observable<any>;
    abstract delete(id: number): Observable<any>;
    abstract getUrlForImport(): string;
    abstract getCsvHeaders(): string[];
    abstract import(importObjects: T[]);
    abstract getRepoName(): string;
    abstract buildObject(a?, b?, c?, d?, e?, f?, g?, h?, i?, j?, k?, l?, m?): T;
    abstract buildImportObjects(importObjectStringArray): T[];
}
import { Component, OnInit } from '@angular/core';
import { ModifierRepository } from 'src/app/models/modifier/modifier.repository';
import { MatDialog } from '@angular/material';
import { AddModifierComponent } from './add-modifier/add-modifier.component';
import { EditModifierComponent } from './edit-modifier/edit-modifier.component';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LocalizationService } from 'src/app/Services/localization.service';


@Component({
  selector: 'app-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.scss']
})
export class ModifierComponent implements OnInit {

  all: Modifier[];
  showed: Modifier[];

  constructor(
    private modifierRepo: ModifierRepository,
    private dialog: MatDialog,
    private warengruppeRepo: WarengruppeRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.modifierRepo.getAll().subscribe((modifier: Modifier[]) => {
      this.all = modifier;
      this.showed = modifier;
    });
  }

  edit(modifier: Modifier) {
    this.dialogProvider.openAddOrEditDialog(EditModifierComponent, modifier, () => { this.loadData(); });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddModifierComponent, undefined, () => { this.loadData(); });
  }

  delete(modifier: Modifier) {
    let dependentSc;
    const dependentElements: { type: string, element: string }[] = [];

    this.warengruppeRepo.getAll().then((subCategory: SubCategory[]) => {
      dependentSc = subCategory.filter(filterObjekt => filterObjekt.ModifierID === modifier.ID);

      if (dependentSc.length === 0) {
        this.dialogProvider.openDeleteDialog(modifier.Name, modifier.ID, this.modifierRepo, () => { this.loadData(); });
      } else {
        dependentSc.forEach((depWg: SubCategory) => {
          dependentElements.push(
            { type: this.i18n.translate('subCategory'), element: depWg.Name }
          );
        }
        );
        const datas = {
          status: 'Achtung',
          message: 'Löschen nicht möglich. Folgende Elemente sind von diesem Objekt abhängig:',
          elements: dependentElements
        };
        this.dialogProvider.openConfirmationDialog(datas);
      }
    });
  }


  openFileLoad() {
    const data = {
      exportData: this.showed,
      repo: this.modifierRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }
}

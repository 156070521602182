import { Injectable } from '@angular/core';
import { promise } from 'protractor';
const german = require('../../i18n/de.json');
@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor() { }

  translate(key: string, datas?: { key: string, value: string }[]): string {
    let i18nText: string = german[key] ? german[key] : key;
    if (datas) {
      datas.forEach(data => {
        i18nText = i18nText.replace(`{${data.key}}`, data.value);
      });
    }
    return i18nText;
  }
}



import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { EditPrinterComponent } from '../edit-printer/edit-printer.component';
import { PrinterRepository } from 'src/app/models/printer/printer.repository';

@Component({
  selector: 'app-add-printer',
  templateUrl: './add-printer.component.html',
  styleUrls: ['./add-printer.component.scss']
})
export class AddPrinterComponent implements OnInit {

  printerFormGroup: FormGroup = new FormGroup(
    {
      IPAdress: new FormControl('', Validators.required),
      Description: new FormControl('', Validators.required),
      Port: new FormControl('', Validators.required),
    }
  );

  constructor(
    private dialogRef: MatDialogRef<EditPrinterComponent>,
    private printerRepo: PrinterRepository
  ) { }

  ngOnInit() {
  }

  add() {
    if (this.validate()) {
      this.printerRepo.post(this.buildPrinter()).subscribe(test => console.log(test));
      this.dialogRef.close('submit');
    }
  }

  buildPrinter(): Printer {
    return this.printerRepo.buildObject(
      this.printerFormGroup.get('IPAdress').value,
      this.printerFormGroup.get('Description').value,
      this.printerFormGroup.get('Port').value
    );
  }

  private validate() {
    if (
      this.printerFormGroup.get('IPAdress').valid &&
      this.printerFormGroup.get('Description').valid &&
      this.printerFormGroup.get('Port').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { NutzerRepository } from 'src/app/models/nutzer/nutzer.repository';


@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  // colors = [
  //   '#FB5151', '#FB7C51', '#FBB451', '#FBED51', '#D1FB51',
  //   '#98FB51', '#5FFB51', '#51FB7B', '#51FBB4', '#51FBED',
  //   '#51D1FB', '#5198FB', '#517CFB', '#515FFB', '#7B51FB',
  //   '#B451FB', '#ED51FB', '#FB51D1', '#FB5198', '#FB515F'
  // ]

  @Input() colors: { name: string }[];

  constructor(
    public dialogRef: MatDialogRef<ColorPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[]) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  chooseColor(color) {
    this.dialogRef.close(color);
  }
}

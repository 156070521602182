import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { ArticleComponent } from './components/article/article.component';
import { WarengruppeComponent } from './components/warengruppe/warengruppe.component';
import { SteuerComponent } from './components/steuer/steuer.component';
import { NutzerComponent } from './components/nutzer/nutzer.component';
import { ObergruppeComponent } from './components/obergruppe/obergruppe.component';
import { ModifierComponent } from './components/modifier/modifier.component';
import { BerechtigungComponent } from './components/berechtigung/berechtigung.component';
import { BuchungComponent } from './components/buchung/buchung.component';
import { LoginComponent } from './components/public/login/login.component';
import { KontenComponent } from './components/konten/konten.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { CancelReasonComponent } from './components/cancel-reason/cancel-reason.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { DeviceComponent } from './components/device/device.component';
import { PrinterComponent } from './components/printer/printer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DailyClosingComponent } from './components/daily-closing/daily-closing.component';
import { AddonComponent } from './components/addon/addon.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'navigationBar', component: NavigationBarComponent },
  { path: 'article', component: ArticleComponent },
  { path: 'warengruppe', component: WarengruppeComponent },
  { path: 'steuersatz', component: SteuerComponent },
  { path: 'nutzer', component: NutzerComponent },
  { path: 'obergruppe', component: ObergruppeComponent },
  { path: 'modifier', component: ModifierComponent },
  { path: 'berechtigung', component: BerechtigungComponent },
  { path: 'buchung', component: BuchungComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'konten', component: KontenComponent },
  { path: 'tenant', component: TenantComponent },
  { path: 'cancelReasons', component: CancelReasonComponent },
  { path: 'printer', component: PrinterComponent },
  { path: 'accounts', component: AccountsComponent },
  { path: 'device', component: DeviceComponent },
  { path: 'dailyClosing', component: DailyClosingComponent },
  { path: 'addOn', component: AddonComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

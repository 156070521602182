import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CancelReasonRepository } from 'src/app/models/cancelReason/cancelReason.repository';

@Component({
  selector: 'app-edit-cancel-reason',
  templateUrl: './edit-cancel-reason.component.html',
  styleUrls: ['./edit-cancel-reason.component.scss']
})
export class EditCancelReasonComponent implements OnInit {
  cancelReasonFormgroup: FormGroup = new FormGroup(
    {
      Description: new FormControl(this.data.Description, Validators.required)
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditCancelReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CancelReason,
    private cancelReasonRepo: CancelReasonRepository
  ) { }

  ngOnInit() {
  }

  editCancelReason() {
    if (this.validate()) {
      this.cancelReasonRepo.update(this.buildCancelReason()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  buildCancelReason(): CancelReason {
    return this.cancelReasonRepo.buildObject(
      this.cancelReasonFormgroup.get('Description').value,
      this.data.ID
    );
  }

  validate() {
    if (this.cancelReasonFormgroup.get('Description').valid) {
      return true;
    }
    return false;
  }
}

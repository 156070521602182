import { Component, OnInit } from '@angular/core';
import { EditBerechtigungComponent } from '../edit-berechtigung/edit-berechtigung.component';
import { BerechtigungRepository } from 'src/app/models/berechtigung/berechtigung.repository';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NutzerRepository } from 'src/app/models/nutzer/nutzer.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-add-berechtigung',
  templateUrl: './add-berechtigung.component.html',
  styleUrls: ['./add-berechtigung.component.scss']
})
export class AddBerechtigungComponent implements OnInit {
User
  nutzers: User[];

  berechtigungFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl('', Validators.required),
      Beschreibung: new FormControl('', Validators.required),
      Funktionen: new FormControl('', Validators.required),
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditBerechtigungComponent>,
    private berechtigungRepo: BerechtigungRepository, 
    public i18n: LocalizationService
  ) { }

  ngOnInit() {

  }

  add() {
    if (this.validate()) {
      this.berechtigungRepo.post(this.buildBerechtigung()).subscribe(test => console.log(test));
      this.dialogRef.close('submit');
    }
  }

  buildBerechtigung(): Permission {
    return this.berechtigungRepo.buildObject(
      this.berechtigungFormGroup.get('Name').value,
      this.berechtigungFormGroup.get('Beschreibung').value,
      this.berechtigungFormGroup.get('Funktionen').value
    );
  }

  private validate() {
    if (
      this.berechtigungFormGroup.get('Name').valid &&
      this.berechtigungFormGroup.get('Beschreibung').valid &&
      this.berechtigungFormGroup.get('Funktionen').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';
import { Device } from './device.model';


@Injectable({
    providedIn: 'root'
})
export class DeviceRepository extends AbstractRepository<Device> {


    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/device`;
    }

    post(device) {
        return this.http.post(this.getUrl(), { tenant: this.tenantService.getCurrentTenantID(), device: device });
    }

    getAll(): Promise<Device[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: Device[]) => resolve(res));
        });
    }

    getWithID(id: number): Promise<Device> {
        throw new Error('Method not implemented.');
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }
    update(object: Device): Observable<any> {
        throw new Error('Method not implemented.');
    }
    getUrlForImport(): string {
        throw new Error('Method not implemented.');
    }
    getCsvHeaders(): string[] {
        throw new Error('Method not implemented.');
    }
    import(importObjects: Device[]) {
        throw new Error('Method not implemented.');
    }
    getRepoName(): string {
        throw new Error('Method not implemented.');
    }
    buildObject(a?: any, b?: any, c?: any, d?: any, e?: any, f?: any, g?: any, h?: any, i?: any, j?: any, k?: any, l?: any, m?: any): Device {
        throw new Error('Method not implemented.');
    }

    buildImportObjects(importObjectStringArray: any): Device[] {
        throw new Error('Method not implemented.');
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { KontenRepository } from 'src/app/models/konten/konten.repository';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-konten',
  templateUrl: './konten.component.html',
  styleUrls: ['./konten.component.scss']
})
export class KontenComponent implements OnInit {

  all: TransactionAccount[];
  showed: TransactionAccount[];

  tableOpen = false;
  editOpen = true;
  konten: TransactionAccount;

  constructor(
    private kontenRepo: KontenRepository,
    public i18n: LocalizationService,
    private dialogProvider: DialogProviderService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.getKonten();
  }

  addKonto() {
    this.dialogProvider.openAddOrEditDialog(AddComponent, undefined, () => { this.loadData(); });
  }

  deleteKonto(konto: TransactionAccount) {
    this.dialogProvider.openDeleteDialog(konto.Name, konto.ID, this.kontenRepo, () => { this.loadData(); });
  }

  editKonto(konto: TransactionAccount) {
    this.dialogProvider.openAddOrEditDialog(EditComponent, konto, () => { this.loadData(); });
  }

  openFileLoad() {
    const data = {
      exportData: this.showed,
      repo: this.kontenRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }


  toogleEditWindow() {
    this.tableOpen = !this.tableOpen;
    this.editOpen = !this.editOpen;
  }

  getKonten() {
    this.kontenRepo.getAll().then((konten: TransactionAccount[]) => {
      this.all = konten;
      this.showed = konten;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SteuersatzRepository } from 'src/app/models/steuersatz/steuersatz.repository';
import { MatDialogRef } from '@angular/material';
import { NutzerRepository } from 'src/app/models/nutzer/nutzer.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-add-nutzer',
  templateUrl: './add-nutzer.component.html',
  styleUrls: ['./add-nutzer.component.scss']
})
export class AddNutzerComponent implements OnInit {
  nutzerFormGroup: FormGroup = new FormGroup(
    {
      Login: new FormControl('', Validators.required),
      Name: new FormControl('', Validators.required),
      Berechtigungsgruppe: new FormControl('', Validators.required)
    }
  )
  constructor(public i18n: LocalizationService,private nutzerRepo: NutzerRepository, public dialogRef: MatDialogRef<AddNutzerComponent>) { }

  ngOnInit() {
  }

  add() {
    if (this.validate()) {
      this.nutzerRepo.post(this.buildNutzer()).subscribe(res => this.dialogRef.close("submit"))
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
User
  buildNutzer(): User {
    return this.nutzerRepo.buildObject(
      this.nutzerFormGroup.get('Login').value,
      this.nutzerFormGroup.get('Name').value,
      this.nutzerFormGroup.get('Berechtigungsgruppe').value,
    )
  }

  validate() {
    if (this.nutzerFormGroup.get('Login').valid &&
      this.nutzerFormGroup.get('Name').valid &&
      this.nutzerFormGroup.get('Berechtigungsgruppe').valid) {
      return true;
    }
    return false;
  }

}

import { Component, OnInit } from '@angular/core';
import { PrinterRepository } from 'src/app/models/printer/printer.repository';
import { AddPrinterComponent } from './add-printer/add-printer.component';
import { EditPrinterComponent } from './edit-printer/edit-printer.component';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { ArtikelRepository } from 'src/app/models/artikel/artikel.repository';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.scss']
})
export class PrinterComponent implements OnInit {
  all: Printer[];
  showed: Printer[];

  constructor(
    private printerRepo: PrinterRepository,
    private warengruppeRepo: WarengruppeRepository,
    private artikelRepo: ArtikelRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.printerRepo.getAll().then((printer: Printer[]) => {
      this.all = printer;
      this.showed = printer;
    });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddPrinterComponent, undefined, () => { this.loadData(); });
  }

  edit(printer: Printer) {
    this.dialogProvider.openAddOrEditDialog(EditPrinterComponent, printer, () => { this.loadData(); });
  }

  delete(printer: Printer) {
    let dependentWg;
    let dependentArt;
    const dependentElements: { type: string, element: string }[] = [];

    this.warengruppeRepo.getAll().then((warengruppe: SubCategory[]) => {
      dependentWg = warengruppe.filter(filterWg =>
        parseInt(filterWg.Printer, 10) === printer.ID
      );

      this.artikelRepo.getAll().then((artikel: Article[]) => {
        dependentArt = artikel.filter(filterArt =>
          parseInt(filterArt.Printer, 10) === printer.ID
        );

        if (dependentWg.length === 0 && dependentArt.length === 0) {
          this.dialogProvider.openDeleteDialog(printer.Description, printer.ID, this.printerRepo, () => { this.loadData(); });
        } else {
          dependentWg.forEach((depWg: SubCategory) => {
            dependentElements.push(
              { type: this.i18n.translate('subCategory'), element: depWg.Name }
            );
          });
          dependentArt.forEach(depArt => {
            dependentElements.push(
              { type: this.i18n.translate('article'), element: depArt.Name }
            );
          });
          const datas = {
            status: 'Achtung',
            message: 'Löschen nicht möglich. Folgende Elemente sind von diesem Objekt abhängig:',
            elements: dependentElements
          };
          this.dialogProvider.openConfirmationDialog(datas);
        }
      });
    });
  }

  openFileLoad() {
    const data = {
      exportData: this.showed,
      repo: this.printerRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DashboardDataProviderService } from '../../app/Services/dashboard-data-provider.service';
import { DashboardMode } from '../../app/models/enums/dashboard-mode';
import { FormControl } from '@angular/forms';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-chart-circle',
  templateUrl: './chart-circle.component.html',
  styleUrls: ['./chart-circle.component.scss']
})
export class ChartCircleComponent implements OnInit, OnChanges {

  circleData: any[];
  view: any[] = [580, 310];

  // options
  showLegend = true;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  // pie
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  loaded = false;

  typeOfOrders: DashboardMode = DashboardMode.count;


  @Input() bookings: Booking[];
  @Input() dashboardMode: DashboardMode;

  constructor(private dashboardDataProvider: DashboardDataProviderService, public i18n: LocalizationService) {
    Object.assign(this, { circleData: this.circleData });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateNutzerData();
  }

  ngOnInit(): void {
    this.calculateNutzerData();
  }

  calculateNutzerData() {
    this.dashboardDataProvider.getUserData(this.dashboardMode, this.bookings).then((response) => {
      this.circleData = response;
      this.loaded = true;
    });
  }

  onSelect(event) {
    console.log(event);
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TenantRepository } from 'src/app/models/tenant/tenant.repository';
import { LocalizationService } from 'src/app/Services/localization.service';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { RegisterComponent } from './register/register.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  showed: Tenant[];

  constructor(
    private tenantRepo: TenantRepository,
    private dialogProvider: DialogProviderService,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.tenantRepo.getAll().then(res => this.showed = res);
  }

  add(tenant: Tenant) {
    this.dialogProvider.openAddOrEditDialog(RegisterComponent, undefined, () => { this.loadData(); });
  }

  delete(tenant: Tenant) {
    this.dialogProvider.openDeleteDialog(tenant.name, tenant.ID, this.tenantRepo, () => { this.loadData(); });
  }

  openFileLoad() {
    const data = {
      export: this.showed,
      repo: this.tenantRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TenantSelectionService {

  constructor(private http: HttpClient, private router: Router) { }

  getUrl(): string {
    return `${environment.backendUrlPublic}/login`;
  }

  login(loginData: { Username: string, Password: string }): Observable<any> {
    return this.http.post(this.getUrl(), loginData);
  }

  setLoggedIn(sessionToken, tenant) {
    localStorage.setItem('@loggedIn', 'true');
    localStorage.setItem('@sessionToken', sessionToken);
    this.setCurrentTenantID(tenant);
    this.router.navigate(['dashboard']);
  }

  loggedIn() {
    return Boolean(localStorage.getItem('@loggedIn'));
  }

  getCurrentTenantID(): number {
    return parseInt(localStorage.getItem('@tenantID'), 10);
  }

  getSessionToken(): string {
    return localStorage.getItem('@sessionToken');
  }

  setCurrentTenantID(id: string) {
    localStorage.setItem('@tenantID', id);
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['']);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ModifierRepository } from 'src/app/models/modifier/modifier.repository';
import { TenantRepository } from 'src/app/models/tenant/tenant.repository';
import { LocalizationService } from 'src/app/Services/localization.service';
import { EditModifierComponent } from '../../modifier/edit-modifier/edit-modifier.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerFormGroup: FormGroup = new FormGroup({
    street: new FormControl('', Validators.required),
    plz: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    taxNumber: new FormControl('', Validators.required),
    telefonNumber: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required)
  });

  constructor(
    public dialogRef: MatDialogRef<EditModifierComponent>,
    private tenantRepo: TenantRepository,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  add() {
    if (this.validate()) {
      this.tenantRepo.post(this.buildTenant()).subscribe(test => console.log(test));
      this.dialogRef.close('submit');
    }
  }

  buildTenant(): Tenant {
    return this.tenantRepo.buildObject(
      this.registerFormGroup.get('name').value,
      this.registerFormGroup.get('street').value,
      this.registerFormGroup.get('plz').value,
      this.registerFormGroup.get('city').value,
      this.registerFormGroup.get('taxNumber').value,
      this.registerFormGroup.get('telefonNumber').value,
      'DE',
      this.registerFormGroup.get('username').value,
    );
  }

  private validate() {
    if (
      this.registerFormGroup.get('username').valid &&
      this.registerFormGroup.get('name').valid &&
      this.registerFormGroup.get('street').valid &&
      this.registerFormGroup.get('plz').valid &&
      this.registerFormGroup.get('city').valid &&
      this.registerFormGroup.get('taxNumber').valid &&
      this.registerFormGroup.get('telefonNumber').valid
    ) {
      return true;
    }
    return false;
  }

}

import { Component, OnInit } from '@angular/core';
import { AddOnRepository } from 'src/app/models/addOn/addOn.repository';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LocalizationService } from 'src/app/Services/localization.service';
import { AddAddonComponent } from './add-addon/add-addon.component';
import { EditAddonComponent } from './edit-addon/edit-addon.component';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss']
})
export class AddonComponent implements OnInit {

  all: AddOn[];
  showed: AddOn[];

  constructor(
    private addOnRepo: AddOnRepository,
    public i18n: LocalizationService,
    private dialogProvider: DialogProviderService
  ) { }

  ngOnInit() {
    this.loadData()
  }


  loadData() {
    this.getKonten();
  }

  addKonto() {
    this.dialogProvider.openAddOrEditDialog(AddAddonComponent, undefined, () => { this.loadData(); });
  }

  deleteKonto(addOn: AddOn) {
    this.dialogProvider.openDeleteDialog(addOn.Name, addOn.ID, this.addOnRepo, () => { this.loadData(); });
  }

  editKonto(addOn: AddOn) {
    this.dialogProvider.openAddOrEditDialog(EditAddonComponent, addOn, () => { this.loadData(); });
  }


  getKonten() {
    this.addOnRepo.getAll().then((addOn: AddOn[]) => {
      this.all = addOn;
      this.showed = addOn;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ObergruppeRepository } from 'src/app/models/obergruppe/obergruppe.repository';
import { MatDialogRef, MatDialog } from '@angular/material';
import { EditObergruppeComponent } from '../edit-obergruppe/edit-obergruppe.component';
import { ColorPickerComponent } from 'src/assets/color-picker/color-picker.component';
import { IconPickerComponent } from 'src/assets/icon-picker/icon-picker.component';
import { LocalizationService } from 'src/app/Services/localization.service';

const colors = [
  '#FE7F4E', '#FD4E87', '#56A4F5', '#864EFE', 'hsl(2, 70%, 65%)',
  'hsl(50, 25%, 65%)', 'hsl(30, 68%, 65%)'
];



@Component({
  selector: 'app-add-obergruppe',
  templateUrl: './add-obergruppe.component.html',
  styleUrls: ['./add-obergruppe.component.scss']
})
export class AddObergruppeComponent implements OnInit {

  oberGruppeFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl('', Validators.required),
      Beschreibung: new FormControl('', Validators.required),
      Farbe: new FormControl('', Validators.required),
      Icon: new FormControl('', Validators.required)
    }
  );

  constructor(
    private obergruppenRepo: ObergruppeRepository,
    public dialogRef: MatDialogRef<EditObergruppeComponent>,
    private dialog: MatDialog,
    public i18n: LocalizationService) { }

  ngOnInit() {
  }

  add() {
    if (this.validate()) {
      this.obergruppenRepo.post(this.buildObergruppe()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  buildObergruppe(): Category {
    return this.obergruppenRepo.buildObject(
      this.oberGruppeFormGroup.get('Name').value,
      this.oberGruppeFormGroup.get('Beschreibung').value,
      this.oberGruppeFormGroup.get('Farbe').value,
      this.oberGruppeFormGroup.get('Icon').value,
    );
  }

  getBorderColor(picker: string) {
    if (this.oberGruppeFormGroup.get(picker).valid) {
      return { border: '3px solid green' };
    } else {
      return { border: '3px solid red' };
    }
  }

  validate() {
    if (
      this.oberGruppeFormGroup.get('Name').valid &&
      this.oberGruppeFormGroup.get('Farbe').valid &&
      this.oberGruppeFormGroup.get('Icon').valid &&
      this.oberGruppeFormGroup.get('Beschreibung').valid) {
      return true;
    }
    return false;
  }

  chooseColor() {
    const dialogRef = this.dialog.open(ColorPickerComponent,
      {
        height: '25%',
        width: '20%',
        panelClass: 'custom-modalbox',
        data: colors,
        disableClose: false,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'cancel') {
        return;
      } else {
        this.oberGruppeFormGroup.get('Farbe').setValue(result);
      }
    });
  }
  
  chooseIcon() {
    const dialogRef = this.dialog.open(IconPickerComponent,
      {
        height: '40%',
        width: '20%',
        panelClass: 'custom-modalbox',
        data: '#009a9b',
        disableClose: false,
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'cancel') {
        return;
      } else {
        this.oberGruppeFormGroup.get('Icon').setValue(`"name":"${result.rnName}", "type":"${result.rnType}"`);
      }
    });
  }

  getColor(): string {
    return this.oberGruppeFormGroup.get('Farbe').value;
  }

  getIconPath() {
    const icon = JSON.parse('{' + this.oberGruppeFormGroup.get('Icon').value + '}');
    return `assets/images/${icon.name}.png`;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class WarengruppeRepository extends AbstractRepository<SubCategory> {

    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/warengruppe`;
    }

    getAll(): Promise<SubCategory[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: SubCategory[]) => resolve(res));
        })
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/warengruppeImport`;
    }

    getCsvHeaders(): string[] {
        return [ // Spalten für die generierte .csv Datei
            'Name',
            'Beschreibung',
            'Steuer1ID',
            'Steuer2ID',
            'Drucker',
            'ModifierID',
            'RabatteID',
            'Color',
            'ObergruppeID'
        ];
    }

    import(importObjects: SubCategory[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    getRepoName(): string {
        return 'Warengruppe-';
    }

    update(warengruppe: SubCategory) {
        return this.http.post(`${this.getUrl()}/update`, warengruppe);
    }

    post(warengruppe: SubCategory) {
        return this.http.post(this.getUrl(), warengruppe);
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    buildObject(
        Tax1ID: number,
        Tax2ID: number,
        Printer: string,
        Name: string,
        Description: string,
        ModifierID: number,
        DiscountID: string,
        Color: string,
        CategoryID: number,
        ID?: number,
    ): SubCategory {
        return {
            Tax1ID,
            Tax2ID,
            Printer,
            Name,
            Description,
            ModifierID,
            DiscountID,
            Color,
            CategoryID,
            ID,
            tenant: this.tenantService.getCurrentTenantID()
        };
    }

    buildImportObjects(importObjectStringArray): SubCategory[] {

        const objectsFromCsv: SubCategory[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const Name = importObjectStringArray[i][0];
            const Beschreibung = importObjectStringArray[i][1];
            const Steuer1ID = parseInt(importObjectStringArray[i][2], 10);
            const Steuer2ID = parseInt(importObjectStringArray[i][3], 10);
            const Drucker = importObjectStringArray[i][4];
            const ModifierID = parseInt(importObjectStringArray[i][5], 10);
            const RabatteID = importObjectStringArray[i][6];
            const Color = importObjectStringArray[i][7];
            const ObergruppeID = parseInt(importObjectStringArray[i][8], 10);

            objectsFromCsv.push(this.buildObject(
                Steuer1ID,
                Steuer2ID,
                Drucker,
                Name,
                Beschreibung,
                ModifierID,
                RabatteID,
                Color,
                ObergruppeID
            ));

        }
        return objectsFromCsv;
    }

    getWithID(id: number): Promise<SubCategory> {
        throw new Error('Method not implemented.');
    }
}

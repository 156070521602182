import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SteuersatzRepository } from 'src/app/models/steuersatz/steuersatz.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-edit-steuer',
  templateUrl: './edit-steuer.component.html',
  styleUrls: ['./edit-steuer.component.scss']
})
export class EditSteuerComponent implements OnInit {

  steuerFormGroup: FormGroup = new FormGroup(
    {
      Steuersatz: new FormControl(this.data.TaxValue, Validators.required),
      Name: new FormControl(this.data.Name, Validators.required),
      Beschreibung: new FormControl(this.data.Description, Validators.required)
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditSteuerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Tax,
    private steuersatzRepo: SteuersatzRepository, 
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  editSteuer() {
    if (this.validate()) {
      this.steuersatzRepo.update(this.buildSteuer()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  buildSteuer(): Tax {
    return this.steuersatzRepo.buildObject(
      this.steuerFormGroup.get('Steuersatz').value,
      this.steuerFormGroup.get('Name').value,
      this.steuerFormGroup.get('Beschreibung').value,
      this.data.ID
    );
  }

  validate() {
    if (this.steuerFormGroup.get('Steuersatz').valid &&
      this.steuerFormGroup.get('Name').valid &&
      this.steuerFormGroup.get('Beschreibung').valid) {
      return true;
    }
    return false;
  }
}

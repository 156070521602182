import { Component, OnInit, Inject } from '@angular/core';
import { KontenRepository } from 'src/app/models/konten/konten.repository';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  zahlungsmethoden = [{
    name: 'Bar',
  },
  {
    name: 'PAYDIREKT',
  },
  {
    name: 'PayPal'
  }
  ];

  typen = [{
    name: 'Einzahlung',
  },
  {
    name: 'Auszahlung',
  },
  ];

  kontenFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl(this.data.Name, Validators.required),
      Kontonummer: new FormControl(this.data.AccountNumber, Validators.required),
      Typ: new FormControl(this.data.Type, Validators.required),
      Zahlungsmethode: new FormControl(this.data.PaymentMethod, Validators.required),
      Gutschein: new FormControl(this.data.Voucher),
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransactionAccount,
    public dialogRef: MatDialogRef<EditComponent>,
    private kontenRepo: KontenRepository, 
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  edit() {
    if (this.validate()) {
      this.kontenRepo.update(this.buildObject()).subscribe(
        res => {
          console.log(res);
          this.dialogRef.close('submit');
        }
      );
    }
  }

  buildObject(): TransactionAccount {
    return this.kontenRepo.buildObject(
      this.kontenFormGroup.get('Name').value,
      this.kontenFormGroup.get('Kontonummer').value,
      this.kontenFormGroup.get('Typ').value,
      this.kontenFormGroup.get('Zahlungsmethode').value,
      this.kontenFormGroup.get('Gutschein').value,
      this.data.ID
    );
  }

  private validate() {
    if (
      this.kontenFormGroup.get('Name').valid &&
      this.kontenFormGroup.get('Kontonummer').valid &&
      this.kontenFormGroup.get('Typ').valid &&
      this.kontenFormGroup.get('Zahlungsmethode').valid &&
      this.kontenFormGroup.get('Gutschein').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

import { Component, OnInit } from '@angular/core';
import { ModifierRepository } from 'src/app/models/modifier/modifier.repository';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { EditModifierComponent } from '../edit-modifier/edit-modifier.component';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-add-modifier',
  templateUrl: './add-modifier.component.html',
  styleUrls: ['./add-modifier.component.scss']
})
export class AddModifierComponent implements OnInit {

  units = [{
    name: 'Prozent',
    sign: '%',
  },
  {
    name: 'Euro',
    sign: '€',
  }
  ];

  modifierFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl('', Validators.required),
      Beschreibung: new FormControl('', Validators.required),
      Farbe: new FormControl('', Validators.required),
      Wert: new FormControl('', Validators.required),
      Einheit: new FormControl('', Validators.required)
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditModifierComponent>,
    private modifierRepo: ModifierRepository,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  add() {
    if (this.validate()) {
      this.modifierRepo.post(this.buildModifier()).subscribe(test => console.log(test));
      this.dialogRef.close('submit');
    }
  }

  buildModifier(): Modifier {
    return this.modifierRepo.buildObject(
      this.modifierFormGroup.get('Name').value,
      this.modifierFormGroup.get('Beschreibung').value,
      this.modifierFormGroup.get('Farbe').value,
      this.modifierFormGroup.get('Wert').value,
      this.modifierFormGroup.get('Einheit').value,
    );
  }

  private validate() {
    if (
      this.modifierFormGroup.get('Name').valid &&
      this.modifierFormGroup.get('Beschreibung').valid &&
      this.modifierFormGroup.get('Farbe').valid &&
      this.modifierFormGroup.get('Wert').valid &&
      this.modifierFormGroup.get('Einheit').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SteuersatzRepository } from 'src/app/models/steuersatz/steuersatz.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-add-steuer',
  templateUrl: './add-steuer.component.html',
  styleUrls: ['./add-steuer.component.scss']
})
export class AddSteuerComponent implements OnInit {
  steuerFormGroup: FormGroup = new FormGroup(
    {
      Steuersatz: new FormControl('', Validators.required),
      Name: new FormControl('', Validators.required),
      Beschreibung: new FormControl('', Validators.required)
    }
  );
  constructor(
    public dialogRef: MatDialogRef<AddSteuerComponent>,
    private steuersatzRepo: SteuersatzRepository, 
    public i18n: LocalizationService) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  add() {
    if (this.validate()) {
      this.steuersatzRepo.post(this.buildSteuer()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  buildSteuer(): Tax {
    return this.steuersatzRepo.buildObject(
      this.steuerFormGroup.get('Steuersatz').value,
      this.steuerFormGroup.get('Name').value,
      this.steuerFormGroup.get('Beschreibung').value,
    );
  }

  validate() {
    if (this.steuerFormGroup.get('Steuersatz').valid &&
      this.steuerFormGroup.get('Name').valid &&
      this.steuerFormGroup.get('Beschreibung').valid) {
      return true;
    }
    return false;
  }

}

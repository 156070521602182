import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class AddOnRepository extends AbstractRepository<AddOn> {


    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/addon`;
    }

    getUrlForImport(): string {
        return ``;
    }

    getCsvHeaders(): string[] {
        return [
            // Spalten für die generierte .csv Datei
            'ID',
            'Name',
            'Price',
        ];
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */
    import(importObjects: AddOn[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    getRepoName(): string {
        return 'Artikel-';
    }

    post(artikel: AddOn) {
        return this.http.post(this.getUrl(), artikel);
    }

    getAll(): Promise<AddOn[]> {
        return new Promise((resolve, reject) => {

            this.http.get(`${this.getUrl()}`).subscribe((res: AddOn[]) => resolve(res));
        });
    }

    getWithID(id: number): Promise<AddOn> {
        throw new Error('Method not implemented.');
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                ID: id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    update(artikel: AddOn) {
        return this.http.post(`${this.getUrl()}/update`, artikel);
    }

    buildObject(Name: string, Price: number, ID?: number,): AddOn {
        return {
            Name,
            Price,
            ID, 
            tenant: this.tenantService.getCurrentTenantID()
        };
    }

    buildImportObjects(importObjectStringArray: any): AddOn[] {
        throw new Error('Method not implemented.');
    }


}

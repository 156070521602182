import { Component, OnInit } from '@angular/core';
import { TenantSelectionService } from './Services/tenant-selection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private tenantService: TenantSelectionService) { }

  ngOnInit(): void {
  }
}

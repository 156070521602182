import { Component, OnInit } from '@angular/core';
import { DailyClosingRepository } from 'src/app/models/dailyClosing/dailyClosing.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-daily-closing',
  templateUrl: './daily-closing.component.html',
  styleUrls: ['./daily-closing.component.scss']
})
export class DailyClosingComponent implements OnInit {
  showed;
  all: DailyClosing[];
  constructor(private repo: DailyClosingRepository, public i18n: LocalizationService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.repo.getAll().then((dailyClosings: DailyClosing[]) => {
      this.all = dailyClosings;
      this.showed = this.all.map(dailyClosing => {
        let closingData = JSON.parse(dailyClosing.ClosingData);
        return ({
          DayNumber: dailyClosing.DayNumber,
          Start: dailyClosing.Start,
          End: dailyClosing.End === null ? 'empty' : dailyClosing.End,
          DeviceID: dailyClosing.DeviceID,
          UserName: dailyClosing.UserName,
          UserID: dailyClosing.UserID,
          totalSales: closingData ? closingData.totalSales : ''
        });
      });
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { CancelReasonRepository } from 'src/app/models/cancelReason/cancelReason.repository';
import { MatDialog } from '@angular/material';
import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { AddCancelReasonComponent } from './add-cancel-reason/add-cancel-reason.component';
import { EditCancelReasonComponent } from './edit-cancel-reason/edit-cancel-reason.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';

@Component({
  selector: 'app-cancel-reason',
  templateUrl: './cancel-reason.component.html',
  styleUrls: ['./cancel-reason.component.scss']
})
export class CancelReasonComponent implements OnInit {
  all: CancelReason[];
  showed: CancelReason[];

  constructor(private cancelReasonRepo: CancelReasonRepository, private dialogProvider: DialogProviderService,) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.cancelReasonRepo.getAll().subscribe((cancelReasons: CancelReason[]) => {
      console.log(cancelReasons)
      this.all = cancelReasons;
      this.showed = cancelReasons;
    });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddCancelReasonComponent, undefined, () => this.loadData());

  }

  delete(cancelReason: CancelReason) {
    this.dialogProvider.openDeleteDialog(cancelReason.Description, cancelReason.ID, this.cancelReasonRepo, () => { this.loadData(); });
  }

  edit(cancelReason: CancelReason) {
    this.dialogProvider.openAddOrEditDialog(EditCancelReasonComponent, cancelReason, () => this.loadData());
  }

  openFileLoad() {

    const data = {
      exportData: this.showed,
      repo: this.cancelReasonRepo
    };
    this.dialogProvider.openFileLoadDialog(data, () => { this.loadData(); });
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ObergruppeRepository } from 'src/app/models/obergruppe/obergruppe.repository';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { ColorPickerComponent } from 'src/assets/color-picker/color-picker.component';
import { IconPickerComponent } from 'src/assets/icon-picker/icon-picker.component';
import { LocalizationService } from 'src/app/Services/localization.service';

const colors = [
  '#FE7F4E', '#FD4E87', '#56A4F5', '#864EFE', 'hsl(2, 70%, 65%)',
  'hsl(50, 25%, 65%)', 'hsl(30, 68%, 65%)'
];

@Component({
  selector: 'app-edit-obergruppe',
  templateUrl: './edit-obergruppe.component.html',
  styleUrls: ['./edit-obergruppe.component.scss']
})
export class EditObergruppeComponent implements OnInit {



  oberGruppeFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl(this.data.Name, Validators.required),
      Beschreibung: new FormControl(this.data.Description, Validators.required),
      Farbe: new FormControl(this.data.Color, Validators.required),
      Icon: new FormControl(this.data.Icon, Validators.required),
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditObergruppeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Category,
    private obergruppenRepo: ObergruppeRepository,
    private dialog: MatDialog,
    public i18n: LocalizationService
  ) { }

  ngOnInit() { }

  edit() {
    if (this.validate()) {
      this.obergruppenRepo.update(this.buildObergruppe()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  buildObergruppe(): Category {

    return this.obergruppenRepo.buildObject(
      this.oberGruppeFormGroup.get('Name').value,
      this.oberGruppeFormGroup.get('Beschreibung').value,
      this.oberGruppeFormGroup.get('Farbe').value,
      this.oberGruppeFormGroup.get('Icon').value,
      this.data.ID
    );
  }

  validate() {
    if (
      this.oberGruppeFormGroup.get('Name').valid &&
      this.oberGruppeFormGroup.get('Farbe').valid &&
      this.oberGruppeFormGroup.get('Beschreibung').valid &&
      this.oberGruppeFormGroup.get('Icon').valid) {
      return true;
    }
    return false;
  }



  chooseColor() {
    const dialogRef = this.dialog.open(ColorPickerComponent,
      {
        height: '40%',
        width: '20%',
        panelClass: 'custom-modalbox',
        data: colors,
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'cancel') {
        return;
      } else {
        this.oberGruppeFormGroup.get('Farbe').setValue(result);
      }
    });
  }

  getBorderColor(picker: string) {
    if (this.oberGruppeFormGroup.get(picker).valid) {
      return { border: '3px solid green' };
    } else {
      return { border: '3px solid red' };
    }
  }

  chooseIcon() {
    const dialogRef = this.dialog.open(IconPickerComponent,
      {
        height: '40%',
        width: '20%',
        panelClass: 'custom-modalbox',
        data: '#009a9b',
        disableClose: false,
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'cancel') {
        return;
      } else {
        this.oberGruppeFormGroup.get('Icon').setValue(`"name":"${result.rnName}", "type":"${result.rnType}"`);
      }
    });
  }

  getColor(): string {
    return this.oberGruppeFormGroup.get('Farbe').value;
  }

  getIconPath() {
    const icon = JSON.parse('{' + this.oberGruppeFormGroup.get('Icon').value + '}');
    return `assets/images/${icon.name}.png`;
  }
}

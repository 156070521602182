import { Component, OnInit } from '@angular/core';
import { BuchungRepository } from 'src/app/models/buchung/buchung.repository';
import { JournalProviderService } from 'src/app/models/journal/journal-provider.service';
import { Observable } from 'rxjs';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-buchung',
  templateUrl: './buchung.component.html',
  styleUrls: ['./buchung.component.scss']
})

export class BuchungComponent implements OnInit {
  showed;
  all;
  journalMode: 'SLEEP' | 'LOADING' | 'LOADED' = 'SLEEP';
  journalLocationUrl: string;
  constructor(
    private repo: BuchungRepository,
    private journalProvider: JournalProviderService,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  async createJournal() {
    this.journalMode = 'LOADING';
    this.journalProvider.createJournal()
      .then(
        (location: any) => {
          console.log(location);
          this.journalLocationUrl = location.Location;
          this.journalMode = 'LOADED';
        });
  }

  loadData() {
    this.repo.getAll().then((buchungen: Booking[]) => {
      this.all = buchungen;
      this.showed = buchungen.slice(0, 200);
    });
  }

}

import {Injectable} from '@angular/core';
import {BuchungRepository} from '../models/buchung/buchung.repository';
import {DashboardMode, DayFilter} from '../models/enums/dashboard-mode';
import * as moment from 'moment';
import {Moment} from 'moment';

const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'];
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface CalculatedBookingData { total: number; count: number; name: string; }
interface CalculatedChartData { name: string; value: number; }

const currentMonth = new Date().getMonth();
@Injectable({
  providedIn: 'root'
})
export class DashboardDataProviderService {
  bookings = null;
  constructor(private bookingRepo: BuchungRepository) {
  }

  // Getting all bookings for the charts.
  async getBookingData(): Promise<Booking[]> {

    return new Promise((resolve) => {
      this.bookingRepo.getAll().then((buchungen: Booking[]) => {
        this.bookings = buchungen;
        resolve(buchungen);
      });
    });
  }

  // Calculating the top 3 articles from booking data
  async getTop3Articles(typeOfOrders: DashboardMode, bookings: Booking[], top: number): Promise<{ name: string, value: number }[]> {

    // if bookings are empty just returning empty array
    if (bookings == null) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }


    const orders: CalculatedBookingData[] = [];
    let chartData: CalculatedChartData[] = [];

    // Getting products
    return new Promise((resolve) => {

      // Setting orders to the orders array in order to know how many of them we sold and whats the total price
      bookings.map((booking) => {
        // Checking if we already defined the article id before if its already defined just updating the values
        // if not creating the array with article id
        if (orders[booking.ArticleID] !== undefined) {
          const article = orders[booking.ArticleID];
          orders[booking.ArticleID] = {
            total: article.total += booking.Price,
            count: article.count += 1,
            name: booking.ArticleName
          };
        } else {
          orders[booking.ArticleID] = {
            total: booking.Price,
            count: 1,
            name: booking.ArticleName
          };
        }
      });

      // Setting bookings to the chart data with the required version and deciding what to show.
      chartData = orders.map((booking) => {
        return {name: booking.name, value: (typeOfOrders === DashboardMode.count) ? booking.count : booking.total};
      }).sort(this.sortByDesending).slice(0, top);

      resolve(chartData);

    });
  }

  // Calculating user profit and count chart from bookings.
  async getUserData(typeOfOrder: DashboardMode, bookings: Booking[]): Promise<{ name: string, value: number }[]> {

    // Checking if the bookings are not empty
    if (bookings == null) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }

    return new Promise((resolve) => {
      const userData: CalculatedBookingData[] = [];
      let chartData: CalculatedChartData[];

      bookings.forEach((data) => {
        if (userData[data.UserID]) {
          userData[data.UserID] = {
            name: data.UserName,
            count: userData[data.UserID].count += 1,
            total: userData[data.UserID].total += data.Price
          };
        } else {
          userData[data.UserID] = { name: data.UserName, count: 1, total: data.Price };
        }
      });


      // Deciding which information we will show
      chartData = userData.map((data) => {
          return { name: data.name, value: (typeOfOrder === DashboardMode.profit ? data.total : data.count) };
      });

      // Resetting array keys
      chartData = chartData.filter((i) => i !== undefined);

      resolve(chartData);

    });


  }


  async getProfitData(countType: DashboardMode, bookings: Booking[], start: Date | null, end: Date | null, dayFilter: any[]): Promise<{
    type: DayFilter,
    data: {
      name: DashboardMode, series: { name: string, value: number }[]
    }[]
  }> {

    if (bookings == null) {
      return new Promise((resolve) => {
        resolve(null);
      });
    }

    return new Promise((resolve) => {

      const dateGroup = bookings.reduce((groupped, booking) => {
        const dateOfOrder = new Date(booking.Timestamp);
        groupped[dateOfOrder.getMonth()] = [...groupped[dateOfOrder.getMonth()] || [], booking];
        return groupped;
      }, []);

      let series: { name: string, value: number, day?: Date }[] = [];

      let startMonth = 0;
      let endMonth = currentMonth;

      if (start != null && end != null) {
        // @ts-ignore
        const diffTime = (end - start);

        startMonth = start.getMonth();
        endMonth = end.getMonth();

        const diffInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let filteredData;

        if (diffInDays < 1) {
          filteredData = this.filterProfitDataByHours(start, end, diffInDays, bookings, countType);
          resolve(filteredData);
          return;
        } else if (diffInDays <= 30) {
          filteredData = this.filterProfitDataByDays(start, end, diffInDays, bookings, countType);
          resolve(filteredData);
          return;
        }
      }
      let sortForSeries = false;
      for (let dateCount = startMonth; dateCount <= endMonth; dateCount++) {
        if (dateGroup[dateCount] !== undefined) {
          let totalForSeries = 0;
          if (dayFilter.length === 0) {
            dateGroup[dateCount].map((bookInside) => {
              totalForSeries += (countType === DashboardMode.profit) ? bookInside.Price : 1;
            });
            series.push({ name: monthsOfYear[dateCount], value: totalForSeries });
          } else {
            sortForSeries = true;
            // filtering by selected weekdays

            dayFilter.map((day) => {
              totalForSeries = 0;
              let startDateForDays: Moment | Date = start;
              if (start == null) {
                startDateForDays = moment().startOf('year');
              }
              const filterDay = moment(startDateForDays)
                .month(dateCount)
                .startOf('month')
                .day(daysOfWeek[day]);
              if (filterDay.date() > 7) {
                filterDay.add(7, 'd');
              }
              const month = filterDay.month();
              while (month === filterDay.month()) {
                dateGroup[dateCount].map((bookInside) => {
                  const dateOfBooking = moment(bookInside.Timestamp);
                  if (dateOfBooking.month() === filterDay.month() && dateOfBooking.date() === filterDay.date()) {
                    totalForSeries += (countType === DashboardMode.profit) ? bookInside.Price : 1;
                  }
                });
                series.push({ name: monthsOfYear[dateCount] + ' - ' + filterDay.format('DD dddd'), value: totalForSeries, day: filterDay.toDate() });
                filterDay.add(7, 'd');
              }
            });
          }

        } else {
          if (dayFilter.length === 0) {
            series.push(({ name: monthsOfYear[dateCount], value: 0 }));
          }
        }
      }

      if (sortForSeries) {
        series = series.sort((firstDate, secondDate) => {
          return firstDate.day.getTime() - secondDate.day.getTime();
        });
      }
      console.log(series);
      return resolve({ type: DayFilter.moreThanOneMonth, data: [{ name: countType, series }] });

    });

  }

  filterProfitDataByDays(start: Date, end: Date, diffInDays: number, bookings: Booking[], countType: DashboardMode): {
    type: DayFilter,
    data: {
      name: DashboardMode,
      series: { name: string, value: number }[]
    }[]
  } {
    const series: { name: string, value: number }[] = [];

    const dateGroup = bookings.reduce((groupped, booking) => {
      const dateOfOrder = new Date(booking.Timestamp);
      groupped[dateOfOrder.getDay()] = [...groupped[dateOfOrder.getDay()] || [], booking];
      return groupped;
    }, []);

    for (let dateCount = start.getDate(); dateCount <= end.getDate(); dateCount++) {
      if (dateGroup[dateCount] !== undefined) {
        let totalForSeries = 0;
        dateGroup[dateCount].map((bookInside) => {
          totalForSeries += (countType === DashboardMode.profit) ? bookInside.Price : 1;
        });

        series.push({ name: dateCount.toString(), value: totalForSeries });
      } else {
        series.push(({ name: dateCount.toString(), value: 0 }));
      }
    }

    return { type: DayFilter.month, data: [{ name: countType, series }] };
  }

  filterProfitDataByHours(start: Date, end: Date, diffInDays: number, bookings: Booking[], countType: DashboardMode): {
    type: DayFilter,
    data: {
      name: DashboardMode,
      series: { name: string, value: number }[]
    }[]
  } {
    const series: { name: string, value: number }[] = [];

    const filteredBookingsByDay = bookings.filter((booking) => {
      const dateOfOrder = new Date(booking.Timestamp);

      if (dateOfOrder.getMonth() === start.getMonth() && dateOfOrder.getDate() === start.getDate()) {
        return true;
      }
    });



    const dateGroup = filteredBookingsByDay.reduce((groupped, booking) => {
      const dateOfOrder = new Date(booking.Timestamp);
      groupped[dateOfOrder.getHours()] = [...groupped[dateOfOrder.getHours()] || [], booking];
      return groupped;
    }, []);



    for (let hourCount = 0; hourCount <= 23; hourCount++) {
      if (dateGroup[hourCount] !== undefined) {
        let totalForSeries = 0;
        dateGroup[hourCount].map((bookInside) => {
          totalForSeries += (countType === DashboardMode.profit) ? bookInside.Price : 1;
        });

        series.push({ name: hourCount.toString(), value: totalForSeries });
      } else {
        series.push(({ name: hourCount.toString(), value: 0 }));
      }
    }

    return { type: DayFilter.daily, data: [{ name: countType, series }] };
  }

  async getProductDateByDates(countType: DashboardMode, bookings: Booking[]): Promise<{
    ID: number,
    name: string,
    series: { name: string, value: number }
  }[]> {

    if (bookings == null) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }

    let productData = [];
    const productNames = [];
    return new Promise((resolve) => {



      const productGroups = bookings.reduce((groupped, product) => {
        if (productNames[product.ArticleID] === undefined) {
          productNames[product.ArticleID] = product.ArticleName;
        }

        groupped[product.ArticleID] = [...groupped[product.ArticleID] || [], product];
        return groupped;
      }, []);


      const dateGroup = productGroups.map((productGroup) => {
        return productGroup.reduce((groupped, booking) => {
          const dateOfOrder = new Date(booking.Timestamp);
          groupped[dateOfOrder.getMonth()] = [...groupped[dateOfOrder.getMonth()] || [], booking];
          return groupped;
        }, []);
      });

      const calculatedDateGroup = dateGroup.map((bookingData, key) => {

        const series = [];

        for (let i = 0; i <= currentMonth; i++) {
          if (bookingData[i] !== undefined) {
            let totalForSeries = 0;
            bookingData[i].map((bookInside) => {
              totalForSeries += (countType === DashboardMode.profit) ? bookInside.Price : 1;
            });

            series.push({ name: monthsOfYear[i], value: totalForSeries });

          } else {
            series.push(({ name: monthsOfYear[i], value: 0 }));
          }
        }

        return { ID: key, name: productNames[key], series };
      });

      productData = calculatedDateGroup.filter((i) => i !== undefined);

      resolve(productData);


    });

  }


  sortByDesending(firstValue, LastValue): number {
    return LastValue.value - firstValue.value;
  }

}

import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav } from '@angular/material';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { LocalizationService } from 'src/app/Services/localization.service';


@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  isSideBarOpen = true;
  isExpanded = true;
  isExpanded2 = true;
  showSubmenu = false;

  showSubSubMenu = false;
  showSubSubMenu2 = false;

  headline = 'Startseite';

  constructor(public i18n: LocalizationService, private tenant: TenantSelectionService) { }

  ngOnInit() {
  }

  loggedIn() {
    return this.tenant.loggedIn();
  }

  logout() {
    this.tenant.logOut();
  }
}

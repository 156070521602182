import { Injectable, SystemJsNgModuleLoader } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadFileService {
  repository;
  parsedCsv = [];

  constructor() { }

  /**
   * *Import -*
   * This method defines a FileReader that reads the data inserted.
   * @param input - The inserted Data (i.e. .csv-file).
   * @param repo - The repository of the inserted objects.
   */

  onFileSelect(input: HTMLInputElement, repo: any) {
    this.repository = repo;
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onload = this.onFileLoad.bind(this);
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  /**
   * *Import -*
   * This method gets the content of the parameter of the inserted data and parses it into
   * an Array (string[][]).
   * @param fileLoadedEvent - The file content read from onFileSelect() (Not assignable).
   */
  onFileLoad(fileLoadedEvent) {
    const textFromFileLoaded = fileLoadedEvent.target.result;
    const csv = [];
    const csvEnd = [];
    const lines = textFromFileLoaded.split('\n');

    lines.forEach((line: string) => {
      const cols: string[] = line.split(';');
      csv.push(cols);
    });
    csv.forEach(row => {
      csvEnd.push(row[0].split(','));
    });
    this.parsedCsv = csvEnd;
  }



  /**
   * *Export -*
   * This method is used to download a .csv-file generated from the given parameter with the selected
   * filename. The csv-file is generated with the method ConvertToCSV.
   * @param data - The data that will be written into the .csv
   * @param filename - The name of the downloaded file
   * @param headerList - The first line of the csv containing the table-headers
   */

  downloadFile(data, filename = 'data', headerList) {
    const csvData = this.ConvertToCSV(data, headerList);

    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  /**
   * *Export -*
   * This method converts the given parameter into a csv-file. It arranges the elements of the objArray
   * according to the headerlist. If an element of the objArray does not posses a specific property from the
   * headerLsit this place will be filled with 'undefined'.
   * @param objArray - The data that will be written into the .csv-file
   * @param headerList - The first The first line of the csv containing the table-headers
   */
  ConvertToCSV(objArray, headerList) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    headerList.forEach(headElement => {
      row += headElement + ',';
    });

    row = row.slice(0, -1);
    str += row + '\r\n';
    let i = 0;
    for (const arrayElement of array) {
      let line = '';
      for (const index of headerList) {
        const head = index;
        line += arrayElement[head] + ',';
      }
      str += line;
      if (i !== array.length - 1) {
        str += '\r\n';
      }
      i++;
    }
    return str;
  }
}

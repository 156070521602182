import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class DailyClosingRepository extends AbstractRepository<DailyClosing> {
    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/allDailyClosing`;
    }
    getAll(): Promise<DailyClosing[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: DailyClosing[]) => resolve(res));
        });
    }
    getWithID(id: number): Promise<DailyClosing> {
        throw new Error('Method not implemented.');
    }
    update(object: DailyClosing): Observable<any> {
        throw new Error('Method not implemented.');
    }
    post(object: DailyClosing): Observable<any> {
        throw new Error('Method not implemented.');
    }
    delete(id: number): Observable<any> {
        throw new Error('Method not implemented.');
    }
    getUrlForImport(): string {
        throw new Error('Method not implemented.');
    }
    getCsvHeaders(): string[] {
        throw new Error('Method not implemented.');
    }
    import(importObjects: DailyClosing[]) {
        throw new Error('Method not implemented.');
    }
    getRepoName(): string {
        throw new Error('Method not implemented.');
    }
    buildObject(a?: any, b?: any, c?: any, d?: any, e?: any, f?: any, g?: any, h?: any, i?: any, j?: any, k?: any, l?: any, m?: any): DailyClosing {
        throw new Error('Method not implemented.');
    }
    buildImportObjects(importObjectStringArray: any): DailyClosing[] {
        throw new Error('Method not implemented.');
    }

    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

}

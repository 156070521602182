import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  sortedDataByType: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      status: string,
      message: string,
      elements: { type: string, element: string }[]
    }
  ) { }

  alreadyWritten(index, element: { type: string, element: string }) {
    return index === 0 ? true : this.data.elements[index - 1].type !== element.type;
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.dialogRef.close('confirm');
  }

}

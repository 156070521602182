import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ModifierRepository } from 'src/app/models/modifier/modifier.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-edit-modifier',
  templateUrl: './edit-modifier.component.html',
  styleUrls: ['./edit-modifier.component.scss']
})
export class EditModifierComponent implements OnInit {

  units = [{
    name: 'Prozent',
    sign: '%',
  },
  {
    name: 'Euro',
    sign: '€',
  }
  ];

  modifierFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl(this.data.Name, Validators.required),
      Beschreibung: new FormControl(this.data.Description, Validators.required),
      Farbe: new FormControl(this.data.Color, Validators.required),
      Wert: new FormControl(this.data.Value, Validators.required),
      Einheit: new FormControl(this.data.Unit, Validators.required)
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Modifier,
    public dialogRef: MatDialogRef<EditModifierComponent>,
    private modifierRepo: ModifierRepository,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  edit() {
    if (this.validate()) {
      this.modifierRepo.update(this.buildModifier()).subscribe(
        res => {
          console.log(res);
          this.dialogRef.close('submit');
        }
      );
    }
  }

  buildModifier(): Modifier {
    return this.modifierRepo.buildObject(
      this.modifierFormGroup.get('Name').value,
      this.modifierFormGroup.get('Beschreibung').value,
      this.modifierFormGroup.get('Farbe').value,
      this.modifierFormGroup.get('Wert').value,
      this.modifierFormGroup.get('Einheit').value,
      this.data.ID
    );
  }

  private validate() {
    if (
      this.modifierFormGroup.get('Name').valid &&
      this.modifierFormGroup.get('Beschreibung').valid &&
      this.modifierFormGroup.get('Farbe').valid &&
      this.modifierFormGroup.get('Wert').valid &&
      this.modifierFormGroup.get('Einheit').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

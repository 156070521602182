import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ArtikelRepository } from 'src/app/models/artikel/artikel.repository';
import { DashboardDataProviderService } from '../../Services/dashboard-data-provider.service';
import { DashboardMode } from '../../models/enums/dashboard-mode';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('artikels') artikelInput: ElementRef<HTMLInputElement>;

  absoluteDataByArticle = [];

  allResults = [];
  countType = DashboardMode.count;
  view: any[] = [600, 320];
  legend = false;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Month';
  yAxisLabel = '';
  timeline = true;
  loaded = true;

  colorScheme = {
    domain: ['#03cbcc', 'green']
  };

  allArtikel: Article[];
  copyAllArtikel: Article[];
  selectedArtikel = new FormControl();

  start = new FormControl();
  top = new FormControl(3);
  end = new FormControl();
  selectType = new FormControl(this.countType);
  dayFilter = new FormControl([]);


  selectedArticles: Article[] = [];
  articleFilterFormControl = new FormControl('');
  showedArtikel: Article[];
  bookings: Booking[] = [];
  openDiagramms: DashboardMode[] = [DashboardMode.count, DashboardMode.count, DashboardMode.count, DashboardMode.count];

  constructor(
    private artikelRepo: ArtikelRepository,
    private dashboardDataProvider: DashboardDataProviderService,
    public i18n: LocalizationService) {
  }

  ngOnInit(): void {
    this.loadBookings();
    this.getArtikel();
  }

  loadBookings() {
    this.dashboardDataProvider.getBookingData().then((bookings) => {
      this.bookings = bookings;
      this.loadChartData(this.openDiagramms[0]);
    });
  }

  loadChartData(countType) {
    this.dashboardDataProvider.getProductDateByDates(countType, this.bookings).then((productsByDate) => {
      this.absoluteDataByArticle = productsByDate.filter(product => product.name !== null );
      this.allResults = productsByDate.filter(product => product.name !== null );
    });
  }

  typeChanged(type: DashboardMode, index) {
    this.openDiagramms[index] = type;
    if (index === 1) {
      this.loadChartData(type);
    }
  }

  getArtikel() {
    this.artikelRepo.getAll().then((artikel: Article[]) => {
      this.allArtikel = [...artikel];
      this.showedArtikel = [...artikel];
      this.copyAllArtikel = [...artikel];
    });

  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  // For autocomplete multi select

  filterArtikel() {
    const filterValue: string = this.articleFilterFormControl.value;
    if (filterValue !== '') {
      this.showedArtikel = this.allArtikel.filter(article =>
        (article.Name.toLowerCase().includes(filterValue) || article.PLU.toLowerCase().includes(filterValue))
        && !this.selectedArticles.map(selectedArticle => selectedArticle.ID).includes(article.ID)
      );
    } else {
      this.showedArtikel = this.allArtikel.filter(article => {
        return !this.selectedArticles.map(selectedArticle => selectedArticle.ID).includes(article.ID);
      });
    }
  }

  remove(artikelID: number): void {
    const index = this.selectedArticles.findIndex((item) => item.ID === artikelID);
    if (index >= 0) {
      this.selectedArticles.splice(index, 1);
    }
    this.filterArtikel();
    this.filterBySelected();
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    const artikel = this.allArtikel.find((item) => item.Name === event.option.viewValue);
    if (artikel !== null) {
      this.selectedArticles = [...this.selectedArticles, artikel];
      this.artikelInput.nativeElement.value = '';
      this.articleFilterFormControl.setValue(null);
      this.filterArtikel();
      this.filterBySelected();
    }
  }

  filterBySelected() {
    const resultList: Article[] = [];
    if (this.selectedArticles.length < 1) {
      this.absoluteDataByArticle = [...this.allResults];
      return;
    }
    this.allResults.map((result) => {
      const findArticle = this.selectedArticles.find((item) => item.ID === result.ID);
      if (findArticle != null) {
        resultList.push(result);
      }
    });
    this.absoluteDataByArticle = [...resultList];
  }
}

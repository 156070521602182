import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantRepository } from 'src/app/models/tenant/tenant.repository';
import { LocalizationService } from 'src/app/Services/localization.service';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formMode: 'LOGIN' | 'REGISTER' = 'REGISTER';

  loginFormGroup: FormGroup = new FormGroup(
    {
      Username: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required),
    });

  registerFormGroup: FormGroup = new FormGroup({
    street: new FormControl('', Validators.required),
    plz: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    taxNumber: new FormControl('', Validators.required),
    telefonNumber: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(
    private tenantService: TenantSelectionService,
    public i18n: LocalizationService,
    private router: Router
  ) {

  }

  ngOnInit() {
    console.log('====================================');
    console.log(this.tenantService.loggedIn());
    console.log('====================================');
    if (this.tenantService.loggedIn() === true) {
      this.router.navigate(['dashboard']);
    }
  }

  changeScreen(mode: 'LOGIN' | 'REGISTER') {
    this.formMode = mode;
  }

  register() {

  }

  login() {
    this.tenantService.login({
      Username: this.loginFormGroup.get('Username').value,
      Password: this.loginFormGroup.get('Password').value
    }).subscribe(
      res => {
        if (res.succesfull) {
          this.tenantService.setLoggedIn(res.sessionToken, res.tenant);
        }
      }
    );
  }
}

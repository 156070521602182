import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class SteuersatzRepository extends AbstractRepository<Tax> {

    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/steuersatz`;
    }

    getAll(): Promise<Tax[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: Tax[]) => resolve(res));
        })
    }

    update(steuersatz: Tax) {
        return this.http.post(`${this.getUrl()}/update`, steuersatz);
    }

    post(steuerstaz: Tax) {
        return this.http.post(this.getUrl(), steuerstaz);
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/steuersatzImport`;
    }

    getCsvHeaders(): string[] {
        return [ // Spalten für die generierte .csv Datei
            'Steuersatz',
            'Name',
            'Beschreibung'
        ];
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */
    import(importObjects: Tax[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    getRepoName(): string {
        return 'Steuersatz-';
    }

    buildObject(
        Steuersatz: number,
        Name: string,
        Beschreibung: string,
        ID?: number,
    ): Tax {
        return {
            TaxValue: Steuersatz,
            Description: Beschreibung,
            Name,
            ID,
            tenant: this.tenantService.getCurrentTenantID()
        };
    }

    buildImportObjects(importObjectStringArray): Tax[] {

        const objectsFromCsv: Tax[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const Steuersatz = parseInt(importObjectStringArray[i][0], 10);
            const Name = importObjectStringArray[i][1];
            const Beschreibung = importObjectStringArray[i][2];

            objectsFromCsv.push(this.buildObject(
                Steuersatz,
                Name,
                Beschreibung,
            ));
        }
        return objectsFromCsv;
    }

    getWithID(id: number): Promise<Tax> {
        throw new Error('Method not implemented.');
    }

}

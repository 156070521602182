import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AddSteuerComponent } from '../../steuer/add-steuer/add-steuer.component';
import { MatDialogRef } from '@angular/material';
import { CancelReasonRepository } from 'src/app/models/cancelReason/cancelReason.repository';

@Component({
  selector: 'app-add-cancel-reason',
  templateUrl: './add-cancel-reason.component.html',
  styleUrls: ['./add-cancel-reason.component.scss']
})
export class AddCancelReasonComponent implements OnInit {
  cancelReasonFormGroup: FormGroup = new FormGroup(
    {
      Description: new FormControl('', Validators.required)
    }
  );
  constructor(
    public dialogRef: MatDialogRef<AddSteuerComponent>,
    private cancelReason: CancelReasonRepository) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  add() {
    if (this.validate()) {
      this.cancelReason.post(this.buildCancelReason()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  buildCancelReason(): CancelReason {
    return this.cancelReason.buildObject(
      this.cancelReasonFormGroup.get('Description').value
    );
  }

  validate() {
    if (this.cancelReasonFormGroup.get('Description').valid) {
      return true;
    }
    return false;
  }

}


import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ModifierRepository } from 'src/app/models/modifier/modifier.repository';
import { DialogData } from 'src/app/models/dialog-data/dialog-data.model';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
  textinputs = [];
  colorInputs = [];

  units = [{
    name: 'Prozent',
    sign: '%',
  },
  {
    name: 'Euro',
    sign: '€',
  }
  ];

  formGroup: FormGroup = this.data.formGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EditDialogComponent>,
  ) { }

  ngOnInit() {
    // Object.entries(this.data.inputs).forEach(([key, value]) => {
    //   const valueTyped: { Type: string, Label: string, formControl: string } = value;

    //   switch (valueTyped.Type.toString().toLowerCase()) {
    //     case 'text': {
    //       this.textinputs.push(value);
    //       break;
    //     }
    //     case 'color': {
    //       this.colorInputs.push(value)
    //       break;
    //     }
    //   }
    // });
  }

  edit() {
    if (this.validate()) {
      this.data.repository.update(this.buildModifier()).subscribe(
        res => {
          console.log(res);
          this.dialogRef.close('submit');
        }
      );
    }
  }

  buildModifier(): Modifier {
    return this.data.repository.buildObject(
      this.formGroup.get('Name').value,
      this.formGroup.get('Beschreibung').value,
      this.formGroup.get('Farbe').value,
      this.formGroup.get('Wert').value,
      this.formGroup.get('Einheit').value,
      this.data.toEdit.ID
    );
  }

  private validate() {
    if (
      this.formGroup.get('Name').valid &&
      this.formGroup.get('Beschreibung').valid &&
      this.formGroup.get('Farbe').valid &&
      this.formGroup.get('Wert').valid &&
      this.formGroup.get('Einheit').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}


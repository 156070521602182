import { Injectable } from '@angular/core';
import { TenantSelectionService } from '../../Services/tenant-selection.service';
import { TenantRepository } from '../tenant/tenant.repository';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { BuchungRepository } from '../buchung/buchung.repository';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class JournalProviderService {

  constructor(
    private tenantService: TenantSelectionService,
    private tenantRepository: TenantRepository,
    private http: HttpClient,
    private authBuilder: BuildAuthService,
    private buchungRepo: BuchungRepository
  ) { }

  getUrl(): string {
    return `${environment.backendURL}/${this.authBuilder.buildAuth()}/journal`;
  }

  createJournal() {
    return new Promise((resolve, reject) => {
      this.createJournalObject().then(journalObject => {
        this.http.post(this.getUrl(), journalObject).subscribe(res => {
          console.log(res);
          resolve(res)
        })
      });
    });
  }

  createJournalObject() {
    return new Promise<Journal>((resolve, reject) => {
      this.createTenantFull().then((tenantFullll) => {
        this.buchungRepo.getSummaryObject().then(a => {
          this.buchungRepo.getFinanzWege().then(finanzwegList => {
            resolve({
              tenantFull: tenantFullll,
              summary: a,
              kassensaldo: this.createKassenSaldo(),
              finanzwege: finanzwegList,
              rabatte: this.createRabatte(),
              transaktionen: this.createTransaktionen(),
              details: this.createDetails()
            });
          });
        });
      });
    });
  }

  createDetails() {
    return { billingDay: '1', endTime: '13.7.2020, 22:00', startTime: '13.7.2020, 10:00' };
  }

  private createTenantFull(): Promise<Tenant> {
    return new Promise<Tenant>((resolve, reject) => {
      this.tenantRepository.getWithID(this.tenantService.getCurrentTenantID()).then((tenant: Tenant) => {
        resolve(tenant);
      });
    });
  }

  private createSummary() {
    this.buchungRepo.getSummaryObject().then(summary => { return summary; });
  }

  private createKassenSaldo() {
    return {
      bar: {
        start: 0,
        soll: 50,
        ist: 50,
        differenz: 0
      },
      gutschein: {
        start: 0,
        soll: 50,
        ist: 50,
        differenz: 0
      },
      kredit: {
        start: 0,
        soll: 50,
        ist: 50,
        differenz: 0
      },
      rechnung: {
        start: 0,
        soll: 50,
        ist: 50,
        differenz: 0
      },
    };
  }



  private createRabatte() {
    return [
      {
        anzahl: 4,
        name: 'Personal',
        netto: 30,
        steuersaetze: 5.7,
        brutto: 35.7
      },
      {
        anzahl: 4,
        name: 'Happy Hour 50%',
        netto: 30,
        steuersaetze: 5.7,
        brutto: 35.7
      },
      {
        anzahl: 4,
        name: 'Personal',
        netto: 30,
        steuersaetze: 5.7,
        brutto: 35.7
      }
    ];
  }

  private createTransaktionen() {
    return [
      {
        anzahl: 3,
        name: 'Gutscheineinzahlung',
        nutzer: 'Kemal',
        einzahlungen: 20,
        auszahlungen: 0
      },
      {
        anzahl: 1,
        name: 'Kassengrundbestand',
        nutzer: 'Kemal',
        einzahlungen: 20,
        auszahlungen: 0
      },
      {
        anzahl: 1,
        name: 'Lieferantzahlung',
        nutzer: 'Kemal',
        einzahlungen: 0,
        auszahlungen: 40
      }
    ];
  }

}

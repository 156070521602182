import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CancelReasonRepository } from 'src/app/models/cancelReason/cancelReason.repository';
import { DeviceRepository } from 'src/app/models/device/device.repository';
import { LocalizationService } from 'src/app/Services/localization.service';
import { AddSteuerComponent } from '../../steuer/add-steuer/add-steuer.component';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss']
})
export class AddDeviceComponent implements OnInit {
  devices = ['SUMNI V1', 'CCV A920'];

  deviceFormGroup: FormGroup = new FormGroup(
    {
      device: new FormControl('', Validators.required)
    }
  );

  constructor(
    public dialogRef: MatDialogRef<AddDeviceComponent>,
    private cancelReason: CancelReasonRepository,
    public i18n: LocalizationService,
    private repo: DeviceRepository
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  add() {
    if (this.validate()) {
      this.repo.post(this.deviceFormGroup.get('device').value).subscribe(res => {
        this.dialogRef.close('submit');
      });
    }
  }

  validate() {
    if (this.deviceFormGroup.get('device').valid) {
      return true;
    }
    return false;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddOnRepository } from 'src/app/models/addOn/addOn.repository';
import { LocalizationService } from 'src/app/Services/localization.service';

@Component({
  selector: 'app-edit-addon',
  templateUrl: './edit-addon.component.html',
  styleUrls: ['./edit-addon.component.scss']
})
export class EditAddonComponent implements OnInit {

  kontenFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl(this.data.Name, Validators.required),
      Price: new FormControl(this.data.Price, Validators.required),
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddOn,
    public dialogRef: MatDialogRef<EditAddonComponent>,
    private kontenRepo: AddOnRepository,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  edit() {
    if (this.validate()) {
      this.kontenRepo.update(this.buildObject()).subscribe(
        res => {
          console.log(res);
          this.dialogRef.close('submit');
        }
      );
    }
  }

  buildObject(): AddOn {
    return this.kontenRepo.buildObject(
      this.kontenFormGroup.get('Name').value,
      this.kontenFormGroup.get('Price').value,
      this.data.ID
    );
  }

  private validate() {
    if (
      this.kontenFormGroup.get('Name').valid &&
      this.kontenFormGroup.get('Price').valid
    ) {
      return true;
    }
    return false;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AddOnRepository } from 'src/app/models/addOn/addOn.repository';
import { LocalizationService } from 'src/app/Services/localization.service';
import { EditAddonComponent } from '../edit-addon/edit-addon.component';

@Component({
  selector: 'app-add-addon',
  templateUrl: './add-addon.component.html',
  styleUrls: ['./add-addon.component.scss']
})
export class AddAddonComponent implements OnInit {

  kontenFormGroup: FormGroup = new FormGroup(
    {
      Name: new FormControl('', Validators.required),
      Price: new FormControl(1.5, Validators.required),
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditAddonComponent>,
    private addOnRepo: AddOnRepository,
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
  }

  add() {
    if (this.validate()) {
      this.addOnRepo.post(this.buildAddOn()).subscribe(test => console.log(test));
      this.dialogRef.close('submit');
    }
  }

  private validate() {
    if (
      this.kontenFormGroup.get('Name').valid &&
      this.kontenFormGroup.get('Price').valid
    ) {
      return true;
    }
    return false;
  }

  buildAddOn(): AddOn {
    return this.addOnRepo.buildObject(
      this.kontenFormGroup.get('Name').value,
      this.kontenFormGroup.get('Price').value
    );
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}

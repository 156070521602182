import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { SteuersatzRepository } from 'src/app/models/steuersatz/steuersatz.repository';
import { ModifierRepository } from 'src/app/models/modifier/modifier.repository';
import { ObergruppeRepository } from 'src/app/models/obergruppe/obergruppe.repository';
import { ColorPickerComponent } from 'src/assets/color-picker/color-picker.component';
import { PrinterRepository } from 'src/app/models/printer/printer.repository';
import { LocalizationService } from 'src/app/Services/localization.service';


const colors = [
  '#FB5151', '#FB7C51', '#FBB451', '#FBED51', '#D1FB51',
  '#98FB51', '#5FFB51', '#51FB7B', '#51FBB4', '#51FBED',
  '#51D1FB', '#5198FB', '#517CFB', '#515FFB', '#7B51FB',
  '#B451FB', '#ED51FB', '#FB51D1', '#FB5198', '#FB515F'
];


@Component({
  selector: 'app-edit-warengruppe',
  templateUrl: './edit-warengruppe.component.html',
  styleUrls: ['./edit-warengruppe.component.scss']
})
export class EditWarengruppeComponent implements OnInit {
  modifiers: Modifier[];
  steuerSaetze: Tax[];
  obergruppen: Category[];
  printers: Printer[];
  warengruppeFormGroup: FormGroup = new FormGroup(
    {
      Steuersatz1: new FormControl(this.data.Tax1ID, Validators.required),
      Steuersatz2: new FormControl(this.data.Tax2ID, Validators.required),
      Printer: new FormControl(this.data.Printer, Validators.required),
      Name: new FormControl(this.data.Name, Validators.required),
      Beschreibung: new FormControl(this.data.Description, Validators.required),
      Modifier: new FormControl(this.data.ModifierID, Validators.required),
      Rabatte: new FormControl(this.data.DiscountID, Validators.required),
      Farbe: new FormControl(this.data.Color, Validators.required),
      Obergruppe: new FormControl(this.data.CategoryID, Validators.required),
    }
  );

  constructor(
    public dialogRef: MatDialogRef<EditWarengruppeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubCategory,
    private warengruppeRepo: WarengruppeRepository,
    private steuerRepo: SteuersatzRepository,
    private modifierRepo: ModifierRepository,
    private obergruppeRepo: ObergruppeRepository,
    private dialog: MatDialog,
    private printerRepo: PrinterRepository,
    public i18n: LocalizationService

  ) { }

  ngOnInit() {
    this.loadData();
  }

  edit() {
    if (this.validate()) {
      this.warengruppeRepo.update(this.buildArtikel()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
  loadData() {
    this.getModifier();
    this.getSteuersatz();
    this.getObergruppen();
    this.getPrinter();
  }

  getObergruppen() {
    this.obergruppeRepo.getAll().then(
      (obergruppen: Category[]) => {
        this.obergruppen = obergruppen;
      }
    );
  }

  getModifier() {
    this.modifierRepo.getAll().subscribe(
      (modifier: Modifier[]) => {
        this.modifiers = modifier;
      });
  }

  getSteuersatz() {
    this.steuerRepo.getAll().then((steuerSaetze: Tax[]) => {
      this.steuerSaetze = steuerSaetze;
    }
    );
  }

  getPrinter() {
    this.printerRepo.getAll().then((printer: Printer[]) => {
      this.printers = printer;
    }
    );
  }

  buildArtikel(): SubCategory {
    return this.warengruppeRepo.buildObject(
      this.warengruppeFormGroup.get('Steuersatz1').value,
      this.warengruppeFormGroup.get('Steuersatz2').value,
      this.warengruppeFormGroup.get('Printer').value,
      this.warengruppeFormGroup.get('Name').value,
      this.warengruppeFormGroup.get('Beschreibung').value,
      this.warengruppeFormGroup.get('Modifier').value,
      this.warengruppeFormGroup.get('Rabatte').value,
      this.warengruppeFormGroup.get('Farbe').value,
      this.warengruppeFormGroup.get('Obergruppe').value,
      this.data.ID
    );
  }

  validate() {
    if (
      this.warengruppeFormGroup.get('Steuersatz1').valid &&
      this.warengruppeFormGroup.get('Steuersatz2').valid &&
      this.warengruppeFormGroup.get('Printer').valid &&
      this.warengruppeFormGroup.get('Name').valid &&
      this.warengruppeFormGroup.get('Beschreibung').valid &&
      this.warengruppeFormGroup.get('Modifier').valid &&
      this.warengruppeFormGroup.get('Rabatte').valid &&
      this.warengruppeFormGroup.get('Farbe').valid &&
      this.warengruppeFormGroup.get('Obergruppe').valid
    ) {
      return true;
    }
    return false;
  }


  getBorderColor(picker: string) {
    if (this.warengruppeFormGroup.get(picker).valid) {
      return { border: '3px solid green' };
    } else {
      return { border: '3px solid red' };
    }
  }

  chooseColor() {
    const dialogRef = this.dialog.open(ColorPickerComponent,
      {
        height: '25%',
        width: '20%',
        panelClass: 'custom-modalbox',
        data: colors,
        disableClose: false,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'cancel') {
        return;
      } else {
        this.warengruppeFormGroup.get('Farbe').setValue(result);
      }
    });
  }

  getColor(): string {
    return this.warengruppeFormGroup.get('Farbe').value;
  }

}

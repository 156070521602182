import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class KontenRepository extends AbstractRepository<TransactionAccount> {

    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/konten`;
    }

    post(konten: TransactionAccount) {
        return this.http.post(this.getUrl(), konten);
    }

    getAll(): Promise<TransactionAccount[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: TransactionAccount[]) => resolve(res));
        })
    }

    getWithID(id: number): Promise<TransactionAccount> {
        throw new Error('Method not implemented.');
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    update(konto: TransactionAccount) {
        return this.http.post(`${this.getUrl()}/update`, konto);
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/kontenImport`;
    }

    getCsvHeaders(): string[] {
        return [ // Spalten für die generierte .csv Datei
            'Name',
            'Kontonummer',
            'Typ',
            'Zahlungsmethode',
            'Gutschein'
        ];
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */
    import(importObjects: TransactionAccount[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    getRepoName(): string {
        return 'Konten-';
    }

    buildObject(
        Name: string,
        AccountNumber: string,
        Type: string,
        PaymentMethod: number,
        Voucher?: string,
        ID?: number,
    ): TransactionAccount {
        return {
            Name,
            AccountNumber,
            Type,
            PaymentMethod,
            Voucher,
            ID,
            tenant: this.tenantService.getCurrentTenantID(),
        };
    }

    buildImportObjects(importObjectStringArray): TransactionAccount[] {

        const objectsFromCsv: TransactionAccount[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const Name = importObjectStringArray[i][0];
            const Kontonummer = importObjectStringArray[i][1];
            const Typ = importObjectStringArray[i][2];
            const Zahlungsmethode = importObjectStringArray[i][3];
            const Gutschein = importObjectStringArray[i][4];

            objectsFromCsv.push(this.buildObject(
                Name,
                Kontonummer,
                Typ,
                Zahlungsmethode,
                Gutschein
            ));
        }
        return objectsFromCsv;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';
import { SteuersatzRepository } from '../steuersatz/steuersatz.repository';
import { BookingAction } from '../enums/booking-action';
import * as moment from 'moment';
import { ArtikelRepository } from '../artikel/artikel.repository';
import { AbstractRepository } from '../repository/abstract-repository';


@Injectable({
    providedIn: 'root'
})
export class BuchungRepository extends AbstractRepository<Booking> {


    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService, private steuerRepo: SteuersatzRepository) {
        super();
    }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/buchung`;
    }

    post(buchung: Booking) {
        return this.http.post(this.getUrl(), buchung);
    }

    getAll(): Promise<Booking[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.getUrl()}`).subscribe((res: Booking[]) => resolve(res));
        });
    }

    getWithID(id: number): Promise<Booking> {
        throw new Error('Method not implemented.');
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    update(buchung: Booking) {
        return this.http.post(`${this.getUrl()}/update`, buchung);
    }

    getSummaryObject(): Promise<{
        brutto: number,
        netto: number,
        steuersaetze: number,
        rechnungen: number,
        storniert: number,
        rabatt: number,
        kassenoeffnung: number,
        z_zahler: number,
        firstBill: string,
        lastBill: string
    }> {
        let brutto: number = 0;
        let netto: number = 0;
        let steuersaetze: number;
        let rechnungen: number;
        let storniert: number;
        let rabatt: number;
        let kassenoeffnung: number;
        let z_zahler: number;
        let firstBill: string;

        return new Promise((resolve, reject) => {
            this.steuerRepo.getAll().then((taxes: Tax[]) => {
                this.getAll().then((bookings: Booking[]) => {
                    bookings.forEach(booking => {
                        brutto = Number((brutto + booking.Price).toFixed());
                        netto += Number(
                            this.calculateFromBruttoToNetto(booking.Price, this.getTaxWithIDInPerCent(booking.TaxID, taxes))
                        );
                        steuersaetze = brutto - netto;
                        rechnungen = this.getNumberOfInvoices(bookings);
                        storniert = this.getNumberOfStornos(bookings);
                        rabatt = 0; // ToDo :  Still have to be implemented
                        kassenoeffnung = this.getCashBoxOpenCount(bookings);
                        z_zahler = 0; // ToDo :  Still have to be implemented

                    });
                    const format = 'DD-MM-YYYY HH:mm:ss';
                    resolve({
                        brutto,
                        netto: Number(netto.toFixed(2)),
                        steuersaetze: Number(steuersaetze.toFixed(2)),
                        rechnungen,
                        storniert,
                        rabatt,
                        kassenoeffnung,
                        z_zahler,
                        firstBill: moment(new Date(this.getFirstBill(bookings))).format(format),
                        lastBill: moment(new Date(this.getLastBill(bookings))).format(format)
                    });
                });
            });

            steuersaetze = brutto - netto;

        });
    }

    getFinanzWege() {
        return new Promise<any>((resolve, reject) => {
            let finanzwege: {
                name: string,
                prozent: number
                netto: number,
                steuersaetze: number,
                brutto: number
            }[] = [];

            this.getAll().then((bookings: Booking[]) => {
                this.steuerRepo.getAll().then((taxes: Tax[]) => {
                    bookings.forEach((booking: Booking) => {
                        const tax = this.getTaxWithIDInPerCent(booking.TaxID, taxes);
                        const existentFinanzweg = finanzwege.filter(finanzweg => finanzweg.name === booking.PaymentMethod &&
                            finanzweg.prozent === tax);

                        if (existentFinanzweg.length === 0) {
                            finanzwege.push({
                                name: booking.PaymentMethod,
                                prozent: tax,
                                netto: this.calculateFromBruttoToNetto(booking.Price, tax),
                                steuersaetze: Number((booking.Price - this.calculateFromBruttoToNetto(booking.Price, tax)).toFixed(2)),
                                brutto: booking.Price
                            });

                        } else {
                            finanzwege = finanzwege.filter(finanzweg => {
                                return ((
                                    finanzweg.name !== existentFinanzweg[0].name &&
                                    finanzweg.prozent !== tax) ||
                                    (finanzweg.name === existentFinanzweg[0].name && finanzweg.prozent !== tax));
                            });

                            existentFinanzweg.forEach(exFinanzweg => {
                                exFinanzweg.brutto = Number((exFinanzweg.brutto + booking.Price).toFixed(2));
                                exFinanzweg.netto = exFinanzweg.netto + this.calculateFromBruttoToNetto(booking.Price, tax);
                                exFinanzweg.steuersaetze = Number((exFinanzweg.steuersaetze +
                                    (booking.Price - this.calculateFromBruttoToNetto(booking.Price, tax))).toFixed(2));
                                exFinanzweg.netto = Number(exFinanzweg.netto.toFixed(2));
                            });
                            finanzwege.push(existentFinanzweg[0]);
                        }
                    });
                    resolve(finanzwege);
                });
            });
        });
    }

    private getLastBill(bookings: Booking[]): string {
        let bookingCopy = bookings;
        bookingCopy = bookingCopy.sort((a, b) => new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime());
        return bookingCopy[0].Timestamp.toString();
    }

    private getFirstBill(bookings: Booking[]): string {
        let bookingCopy = bookings;
        bookingCopy = bookingCopy.sort((a, b) => new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime());
        return bookingCopy[bookingCopy.length - 1].Timestamp.toString();
    }

    private getCashBoxOpenCount(bookings: Booking[]): number {
        return bookings.filter(booking => booking.Action === BookingAction.cashBoxOpening).length;
    }

    private getNumberOfStornos(bookings: Booking[]): number {
        return bookings.filter(booking => booking.Action === 'storniert').length;
    }

    private getNumberOfInvoices(bookings: Booking[]): number {
        const invoices: string[] = [];

        bookings.forEach(booking => {

            if (!invoices.includes(booking.Reference)) {
                invoices.push(booking.Reference);
            }
        });

        return invoices.length;
    }

    private getTaxWithIDInPerCent(id: number, taxes: Tax[]): number {
        const tax = taxes.filter(tax => tax.ID === id)[0];
        if (tax) {
            return tax.TaxValue;
        } else {
            return 0;
        }
    }

    private calculateFromBruttoToNetto(bruttoPrice: number, steuer: number) {
        return Number(((bruttoPrice) / ((steuer / 100) + 1)).toFixed(2));
    }

    buildObject(
        ArticleID: number,
        UserID: number,
        PaymentMethod: string,
        Type: string,
        Action: string,
        Details: string,
        Price: number,
        SubReference: string,
        Timestamp: Date,
        Discount: number,
        TaxID: number,
        ID?: number,
        Reference?: string,
    ): Booking {
        return {
            ArticleID,
            UserID,
            PaymentMethod,
            Type,
            ID,
            tenant: this.tenantService.getCurrentTenantID(),
            Action,
            Details,
            Price,
            SubReference,
            Reference,
            Timestamp,
            Discount,
            TaxID
        };
    }

    getUrlForImport(): string {
        throw new Error('Method not implemented.');
    }
    getCsvHeaders(): string[] {
        throw new Error('Method not implemented.');
    }
    import(importObjects: Booking[]) {
        throw new Error('Method not implemented.');
    }
    getRepoName(): string {
        throw new Error('Method not implemented.');
    }
    buildImportObjects(importObjectStringArray: any): Booking[] {
        throw new Error('Method not implemented.');
    }
}

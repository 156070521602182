import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { ConfirmationDialogComponent } from 'src/assets/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogProviderService {

  constructor(
    private dialog: MatDialog
  ) { }

  openAddOrEditDialog(component: any, data, afterCloseCallback: () => void) {
    const dialogRef = this.dialog.open(component,
      {
        height: '80%',
        width: '25%',
        panelClass: 'custom-modalbox',
        data,
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'submit') {
        afterCloseCallback();
      }
    }
    );
  }

  openDeleteDialog(data, id, repository, confirmCallback: () => void) {
    const dialogRef = this.dialog.open(DeleteDialogComponent,
      {
        height: '25%',
        width: '20%',
        panelClass: 'custom-modalbox',
        data,
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        repository.delete(id).subscribe(
          res => {
            console.log('Response:', res);
            confirmCallback();
          }
        );
      } else {
        console.log(result);
      }
    }
    );
  }

  openFileLoadDialog(data, confirmCallback: () => void) {
    const dialogRef = this.dialog.open(FileLoadComponent,
      {
        height: '40%',
        width: '25%',
        panelClass: 'custom-modalbox',
        data,
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'submit') {
        confirmCallback();
      }
    }
    );
  }

  openConfirmationDialog(data) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {
        height: '40%',
        width: '35%',
        panelClass: 'custom-modalbox',
        disableClose: false,
        data
      }
    );
  }

}

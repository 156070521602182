import { Injectable } from '@angular/core';
import { TenantSelectionService } from './tenant-selection.service';

@Injectable({
  providedIn: 'root'
})
export class BuildAuthService {

  constructor(private tenantService: TenantSelectionService) { }

  buildAuth(): string {
    return `${this.tenantService.getSessionToken()}/admin/${this.tenantService.getCurrentTenantID()}/DeviceID`;
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WarengruppeRepository } from 'src/app/models/warengruppe/warengruppe-repository';
import { SteuersatzRepository } from 'src/app/models/steuersatz/steuersatz.repository';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ArtikelRepository } from 'src/app/models/artikel/artikel.repository';
import { PrinterRepository } from 'src/app/models/printer/printer.repository';
import { LocalizationService } from 'src/app/Services/localization.service';
@Component({
  selector: 'app-edit-artikel',
  templateUrl: './edit-artikel.component.html',
  styleUrls: ['./edit-artikel.component.scss']
})
export class EditArtikelComponent implements OnInit {

  allArtikel: Article[];
  warengruppen: SubCategory[];
  steuerSaetze: Tax[];
  pluShouldBeUnique;
  printers: Printer[];

  warengruppeSteuer:
    { name: string, ID: number } = { name: 'Wie Warengruppe', ID: 0 };

  artikelFormGroup: FormGroup = new FormGroup(
    {
      PLU: new FormControl(this.data.articleToEdit.PLU, Validators.required),
      Kurzname: new FormControl(this.data.articleToEdit.Shortname, Validators.required),
      Preis1: new FormControl(this.data.articleToEdit.Price1, Validators.required),
      WarengruppeID: new FormControl(this.data.articleToEdit.SubCategoryID, Validators.required),
      Steuersatz1ID: new FormControl(this.data.articleToEdit.Tax1ID, Validators.required),
      Steuersatz2ID: new FormControl(this.data.articleToEdit.Tax2ID, Validators.required),
      LinkArtikel: new FormControl(this.getArtikelWithId(parseInt(this.data.articleToEdit.LinkArticle, 10))),
      Printer: new FormControl(this.data.articleToEdit.Printer, Validators.required),
      Name: new FormControl(this.data.articleToEdit.Name, [Validators.required, Validators.maxLength(35)]),
      Preis2: new FormControl(this.data.articleToEdit.Price2),
      Preis3: new FormControl(this.data.articleToEdit.Price3),
      Preis4: new FormControl(this.data.articleToEdit.Price4),
      Preis5: new FormControl(this.data.articleToEdit.Price5),
      Freipreis: new FormControl(this.data.articleToEdit.Freeprice, Validators.required),
      FreipreisMin: new FormControl(this.data.articleToEdit.FreepriceMin, Validators.required),
      FreipreisMax: new FormControl(this.data.articleToEdit.FreepriceMax, Validators.required),
      Favorit: new FormControl(!!+this.data.articleToEdit.Favorite, Validators.required),
      Addable: new FormControl(!!+this.data.articleToEdit.Addable, Validators.required)
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { articleToEdit: Article, allArtikel: Article[] },
    public dialogRef: MatDialogRef<EditArtikelComponent>,
    private warenGruppeRepo: WarengruppeRepository,
    private steuerRepo: SteuersatzRepository,
    private artikelRepo: ArtikelRepository,
    public i18n: LocalizationService,
    private printerRepo: PrinterRepository

  ) { }

  ngOnInit() {
    console.log(this.data);
    this.loadData();
  }

  loadData() {
    this.getWarenGruppen();
    this.getSteuer();
    this.getPrinter();
  }

  getWarenGruppen() {
    this.warenGruppeRepo.getAll().then((warenGruppen: SubCategory[]) => {
      this.warengruppen = warenGruppen;
    }
    );
  }

  getSteuer() {
    this.steuerRepo.getAll().then((steuerSaetze: Tax[]) => {
      this.steuerSaetze = steuerSaetze;
    }
    );
  }

  getPrinter() {
    this.printerRepo.getAll().then(((printers: Printer[]) => {
      this.printers = printers;
    }));
  }

  editArtikel() {
    if (this.validate()) {
      this.artikelRepo.update(this.buildArtikel()).subscribe(res => console.log(res));
      this.dialogRef.close('submit');
    }
  }

  getArtikelWithId(id: number): Article {
    return this.data.allArtikel.filter((article: Article) => {
      return article.ID === id;
    })[0];
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  buildArtikel(): Article {
    let linkArticleID;
    if (this.artikelFormGroup.get('LinkArtikel').value) {
      linkArticleID = this.artikelFormGroup.get('LinkArtikel').value.ID;
    } else {
      linkArticleID = '';
    }


    if (!this.artikelFormGroup.get('Freipreis').value) {
      return this.artikelRepo.buildObjectWithPreis(
        this.artikelFormGroup.get('PLU').value,
        this.artikelFormGroup.get('Name').value,
        this.artikelFormGroup.get('Kurzname').value,
        this.artikelFormGroup.get('WarengruppeID').value,
        this.artikelFormGroup.get('Steuersatz1ID').value,
        this.artikelFormGroup.get('Steuersatz2ID').value,
        this.artikelFormGroup.get('Printer').value,
        this.artikelFormGroup.get('Favorit').value,
        this.artikelFormGroup.get('Preis1').value,
        this.artikelFormGroup.get('Preis2').value,
        this.artikelFormGroup.get('Preis3').value,
        this.artikelFormGroup.get('Preis4').value,
        this.artikelFormGroup.get('Preis5').value,
        linkArticleID,
        this.artikelFormGroup.get('Addable').value,
        this.data.articleToEdit.ID
      );
    } else {
      console.log(this.artikelFormGroup.get('LinkArtikel').value);
      return this.artikelRepo.buildObjectWithFreipreis(
        this.artikelFormGroup.get('PLU').value,
        this.artikelFormGroup.get('Name').value,
        this.artikelFormGroup.get('Kurzname').value,
        this.artikelFormGroup.get('WarengruppeID').value,
        this.artikelFormGroup.get('Steuersatz1ID').value,
        this.artikelFormGroup.get('Steuersatz2ID').value,
        this.artikelFormGroup.get('Printer').value,
        this.artikelFormGroup.get('Favorit').value,
        this.artikelFormGroup.get('FreipreisMin').value,
        this.artikelFormGroup.get('FreipreisMax').value,
        linkArticleID,
        this.artikelFormGroup.get('Addable').value,
        this.data.articleToEdit.ID
      );
    }
  }

  private validate() {
    if (
      this.validatePlu() &&
      this.artikelFormGroup.get('Kurzname').valid &&
      this.artikelFormGroup.get('Preis1').valid &&
      this.artikelFormGroup.get('WarengruppeID').valid &&
      this.artikelFormGroup.get('Steuersatz1ID').valid &&
      this.artikelFormGroup.get('Steuersatz2ID').valid &&
      this.artikelFormGroup.get('Printer').valid &&
      this.artikelFormGroup.get('Name').valid
    ) {
      return true;
    }
    return false;
  }

  validatePlu() {
    const plu = this.artikelFormGroup.get('PLU').value;
    let valid = true;

    this.data.allArtikel.forEach(article => {
      if (valid && plu !== this.data.articleToEdit.PLU) {
        valid = plu === article.PLU.toString() ? false : true;
      }
    });
    this.pluShouldBeUnique = valid ? '' : 'Die Plu muss eindeutig sein';
    return valid && this.artikelFormGroup.get('PLU').valid ? true : false;
  }


  nameOfArtikel = (artikel: Article): string => {
    if (artikel) {
      return artikel.Name;
    } else {
      return '';
    }
  }

  filter() {
    const value = this.artikelFormGroup.get('LinkArtikel').value;
    this.allArtikel = this.data.allArtikel.filter(artikel => artikel.Name.toLowerCase().includes(value.toString().toLowerCase()));
  }
}

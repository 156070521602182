import { Component, OnInit } from '@angular/core';
import { NutzerRepository } from 'src/app/models/nutzer/nutzer.repository';
import { MatDialog } from '@angular/material';
import { EditNutzerComponent } from './edit-nutzer/edit-nutzer.component';
import { AddNutzerComponent } from './add-nutzer/add-nutzer.component';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { FileLoadComponent } from 'src/assets/file-load/file-load.component';
import { LocalizationService } from 'src/app/Services/localization.service';


@Component({
  selector: 'app-nutzer',
  templateUrl: './nutzer.component.html',
  styleUrls: ['./nutzer.component.scss']
})
export class NutzerComponent implements OnInit {

  all: User[];
  showed: User[];
  constructor(
    private nutzerRepo: NutzerRepository,
    private dialog: MatDialog,
    private dialogProvider: DialogProviderService, 
    public i18n: LocalizationService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.nutzerRepo.getAll().then((nutzer: User[]) => {
      this.all = nutzer;
      this.showed = nutzer;
    });
  }

  edit(nutzer: User) {
    this.dialogProvider.openAddOrEditDialog(EditNutzerComponent, nutzer, () => { this.loadData(); });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddNutzerComponent, undefined, () => { this.loadData(); });
  }

  delete(nutzer: User) {
    this.dialogProvider.openDeleteDialog(nutzer.Name, nutzer.ID, this.nutzerRepo, () => { this.loadData(); });
  }

  openFileLoad() {
    const datas = {
      exportData: this.showed,
      repo: this.nutzerRepo
    };
    this.dialogProvider.openFileLoadDialog(datas, () => { this.loadData(); });
  }
}

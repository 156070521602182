import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Device } from 'src/app/models/device/device.model';
import { DeviceRepository } from 'src/app/models/device/device.repository';
import { DialogProviderService } from 'src/app/Services/dialog-provider.service';
import { LocalizationService } from 'src/app/Services/localization.service';
import { DeleteDialogComponent } from 'src/assets/delete-dialog/delete-dialog.component';
import { AddDeviceComponent } from './add-device/add-device.component';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
  showed;

  constructor(
    private deviceRepo: DeviceRepository,
    public i18n: LocalizationService,
    private dialogProvider: DialogProviderService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.deviceRepo.getAll().then(res => {
      this.showed = res;
    });
  }

  add() {
    this.dialogProvider.openAddOrEditDialog(AddDeviceComponent, undefined, () => this.loadData());
  }

  delete(device: Device) {
    this.dialogProvider.openDeleteDialog(device.ID, device.ID, this.deviceRepo, () => { this.loadData(); });
  }
}

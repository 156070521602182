export enum DashboardMode {
  count= 'Count',
  profit = 'Profit'
}

export enum DayFilter {
  daily = 'Hours',
  month = 'Days',
  moreThanOneMonth = 'Months'
}


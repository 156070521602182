import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantSelectionService } from 'src/app/Services/tenant-selection.service';
import { environment } from 'src/environments/environment';
import { BuildAuthService } from 'src/app/Services/build-auth.service';


@Injectable({
    providedIn: 'root'
})
export class CancelReasonRepository {

    constructor(private http: HttpClient, private tenantService: TenantSelectionService, private authBuilder: BuildAuthService) { }

    getUrl(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/cancelReasons`;
    }

    getUrlForImport(): string {
        return `${environment.backendURL}/${this.authBuilder.buildAuth()}/cancelReasonsImport`;
    }

    getCsvHeaders(): string[] {
        return [
            // Spalten für die generierte .csv Datei
            'Description'
        ];
    }

    /**
     * This method imports the given data into the database.
     * @param importObjects - Array of objects to be inserted.
     */
    import(importObjects: CancelReason[]) {
        return this.http.post(this.getUrlForImport(), importObjects);
    }

    getRepoName(): string {
        return 'CancelReason-';
    }

    post(cancelReason: CancelReason) {
        return this.http.post(this.getUrl(), cancelReason);
    }

    getAll(): Observable<any> {
        return this.http.get(`${this.getUrl()}`);
    }

    getWithID(id: string) {
    }

    delete(id: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                'ID': id
            }
        };
        return this.http.delete(this.getUrl(), options);
    }

    update(cancelReason: CancelReason) {
        return this.http.post(`${this.getUrl()}/update`, cancelReason);
    }

    buildObject(
        Description: string,
        ID?: number,
    ): CancelReason {
        return {
            ID: ID,
            Description: Description,
            tenant: this.tenantService.getCurrentTenantID(),
        };
    }


    /**
     * This method builds objects from the given parameter.
     * @param importObjectStringArray - The Array of objects that need to be build.
     */

    buildImportObjects(importObjectStringArray): CancelReason[] {

        const objectsFromCsv: CancelReason[] = [];

        for (let i = 1; i < importObjectStringArray.length; i++) {
            const Description = importObjectStringArray[i][0];

            objectsFromCsv.push(this.buildObject(
                Description
            ));
        }
        return objectsFromCsv;
    }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadFileService } from 'src/app/Services/load-file.service';

@Component({
  selector: 'app-file-load',
  templateUrl: './file-load.component.html',
  styleUrls: ['./file-load.component.scss']
})
export class FileLoadComponent implements OnInit {

  articlesFromImport: Article[] = [];
  mode = 'import';
  showImport = true;
  dataNameFormGroup: FormGroup = new FormGroup(
    {
      fileName: new FormControl('', Validators.required),
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { exportData: any[], repo },
    public dialogRef: MatDialogRef<FileLoadComponent>,
    public loadFileService: LoadFileService
  ) { }

  ngOnInit() {
  }

  addImport() {
    this.data.repo.import(
      this.data.repo.buildImportObjects(this.loadFileService.parsedCsv))
      .subscribe(res => console.log('IMPORT RESPONSE : ', res));
    this.dialogRef.close('submit');
  }

  /**
   * *Export -*
   * This method downloads a csv-file of the data shown in the component
   * that opened this dialog and closes the dialog. The filename is chooseable.
   */
  dataExport() {
    if (this.validate()) {
      this.loadFileService.downloadFile(this.data.exportData, this.dataNameFormGroup.get('fileName').value, this.data.repo.getCsvHeaders());
      this.dialogRef.close('submit');
    }
  }

  /**
   * *Export -*
   * Downloads the headerlist-pattern of the component that opened this dialog as an csv-file.
   */
  draftDownload() {
    const draftFile = [];
    this.loadFileService.downloadFile(draftFile, this.data.repo.getRepoName() + 'Formatvorlage', this.data.repo.getCsvHeaders());
    this.dialogRef.close('submit');
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  changeView(mode) {
    this.mode = mode;
  }

  validate(): boolean {
    return (this.dataNameFormGroup.get('fileName').valid);
  }

  /**
   * *Import -*
   * This method listens wheter an input is inserted and calls the Listener in
   * LoadFileService.
   * @param input - Inserted input that needs to be parsed.
   * @param repo - The Repository of the component that opened this dialog (i.e. ArtikelComponent).
   */
  onFileSelect(input: HTMLInputElement) {
    this.loadFileService.onFileSelect(input, this.data.repo);
  }

  getStyleForHeaderItem(mode) {
    if (mode === this.mode) {
      return { 'border-bottom': '5px solid darkgreen' };
    } else {
      return {};
    }
  }
}
